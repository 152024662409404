import { UnorderedList, ListItem,AspectRatio, Box, Button, Container, Divider, Flex, HStack, Heading, Stack, Text, VStack, useColorModeValue } from '@chakra-ui/react'
import * as React from 'react'
import { CardContent } from './CardContent'
import { CardWithAvatar } from './CardWithAvatar'

import Navbar from '../../navbar/Navbar'
import Footer from '../../elements/Footer/Footer'

import BASIRI from '../../../assets/img/BASIRI.jpg'
import pattern from './pattern.jpg'




const MotDirecteur = ({dir}) => {
  


  return <>


  <Box as="section" pt="20" pb="12" position="relative">
    {/* <Box position="absolute" inset="0" height="32" bgImage={pattern} opacity={0.5} zIndex={-2}/> */}
    <Box position="absolute" inset="0" height="32" bg="#8d671c" zIndex={-5} />

    <CardWithAvatar
      maxW="xl"
      avatarProps={{
        src: BASIRI,
        name: `ABDELAZIZ AL BASIRI`
      }}
     
    >
      <CardContent>
        {/* Anglais */}
        {dir === 'ltr' && 
        <Heading className='Glook' mb={1} size="lg" fontWeight="extrabold" letterSpacing="tight">
          Eng. Abdul Aziz bin Muhammad Al-Busairi
        </Heading>
        }

        {/* Arabic */}
        {dir === 'rtl' && 
        <Heading  mb={3} size="lg" fontWeight="extrabold" letterSpacing="tight">
          <Text className='cairoB'>المهندس عبد العزيز بن محمد البصيري</Text>
        </Heading>
        }

     
        <Text className='cairoR' fontSize={'sm'} fontWeight={500} bg={useColorModeValue('blue.50', 'blue.900')} p={2} px={3} color={'blue.500'} rounded={'full'}>
          {dir === 'ltr' ? 'Executive Director' : ' المدير العام' }
        </Text>

      

      </CardContent>
    </CardWithAvatar>

  <Container maxW={'6xl'} mt={'7'}>
    <VStack >

      <VStack width='100%'  alignItems={'left'} px={4} py={3} borderWidth='1px' spacing={4} rounded={'md'}>

        <Stack>
         
        {/* Anglais */}
        {dir === 'ltr' && 
          <Text as={"p"} className="Pop" style={{ textAlign: 'justify' }} spacing={3} > 
          Dear Visitors and Partners,<br/><br/>

          As the Director of this esteemed Civil Engineering venture, I am thrilled to extend my warmest greetings to you. ALAMIRIYA is not just a construction company; it's a commitment to excellence, innovation, and sustainable development.<br/><br/>

          At ALAMIRIYA, we believe in building more than just structures; we strive to construct legacies. Our team of dedicated professionals, equipped with cutting-edge technology and a passion for quality, works tirelessly to turn visions into reality.<br/><br/>

          Whether you are a prospective client, a partner, or simply an individual curious about the world of civil engineering, we invite you to explore the wealth of expertise and experience that defines ALAMIRIYA. Our projects stand as testaments to our commitment to delivering projects that not only meet but exceed expectations.<br/><br/>

          As we embark on this journey together, I assure you that ALAMIRIYA is more than just a construction company; it's a partner in your dreams, a creator of lasting impressions, and a builder of a sustainable future.

          Thank you for considering ALAMIRIYA. We look forward to the opportunity to collaborate with you and bring your visions to life.
          </Text>
          }

          {/* Arabic */}
          {dir === 'rtl' && 
          <Text className='cairoR' as={"p"} style={{ textAlign: 'justify' }} spacing={3} >
          أعزائي الزوار والشركاء،<br/><br/>  

          بصفتي مدير هذا المشروع الهندسي المرموق في مجال الهندسة المدنية، يسعدني أن أمد تحياتي الحارة لكم. إن شركة العامرية ليست مجرد شركة إنشاءات، بل هي التزام بالتميز والابتكار والتنمية المستدامة.<br/><br/>

          في العامرية، نؤمن بأننا نقوم ببناء أكثر من مجرد هياكل؛ بل نسعى إلى بناء تراث. يعمل فريقنا المكون من محترفين مخلصين، مجهزين بأحدث التكنولوجيا وشغف بالجودة، بجد واجتهاد لتحويل الرؤى إلى واقع.<br/><br/>

          سواء كنتم عملاء محتملين أو شركاء أو ببساطة أفرادًا مهتمين بعالم الهندسة المدنية، ندعوكم لاستكشاف ثروة الخبرة والكفاءة التي تميز العامرية. تقف مشاريعنا كشهادات على التزامنا بتقديم مشاريع تلبي الآمال وتتجاوزها.<br/><br/>

          مع بداية هذه الرحلة المشتركة، أؤكد لكم أن العامرية ليست مجرد شركة إنشاءات؛ بل هي شريك في أحلامكم، ومبتكر لانطباعات دائمة، وبانية لمستقبل مستدام. شكرًا لاهتمامكم بالعامرية. نتطلع إلى فرصة التعاون معكم وتحقيق رؤاكم

        </Text>
          }

        </Stack>
        <Divider m={0}/>

       

      </VStack>

    </VStack>
  </Container>
  </Box>

  <Footer dir={dir}/>
  </>
}

export default MotDirecteur