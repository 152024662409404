import React from 'react'
import {
    Container,
    SimpleGrid,
    Image,
    Link,
    Heading,
    Text,
    Stack,
    StackDivider,
    Icon,
    useBreakpointValue,
    HStack,
    VStack,
    Box,
    Divider,
    IconButton,
    createIcon
  } from '@chakra-ui/react';
  import img4 from '../../../assets/img/ai/img4.jpg'
  import img1 from '../../../assets/img/ai/img1.jpg'
  import img2 from '../../../assets/img/ai/img2.jpg'
  import img3 from '../../../assets/img/grue.jpg'

import SocialButtonGroup from './SocialButtonGroup';
import { FcFactoryBreakdown } from "react-icons/fc";
import { MdMoreTime } from "react-icons/md";
import { MdEngineering } from "react-icons/md";
import { PiHandshake } from "react-icons/pi";
import Video from '../../pages/home/Video';
import { useState } from 'react';


const PlayIcon = createIcon({
  displayName: 'PlayIcon',
  viewBox: '0 0 58 58',
  d: 'M28.9999 0.562988C13.3196 0.562988 0.562378 13.3202 0.562378 29.0005C0.562378 44.6808 13.3196 57.438 28.9999 57.438C44.6801 57.438 57.4374 44.6808 57.4374 29.0005C57.4374 13.3202 44.6801 0.562988 28.9999 0.562988ZM39.2223 30.272L23.5749 39.7247C23.3506 39.8591 23.0946 39.9314 22.8332 39.9342C22.5717 39.9369 22.3142 39.8701 22.0871 39.7406C21.86 39.611 21.6715 39.4234 21.5408 39.1969C21.4102 38.9705 21.3421 38.7133 21.3436 38.4519V19.5491C21.3421 19.2877 21.4102 19.0305 21.5408 18.8041C21.6715 18.5776 21.86 18.3899 22.0871 18.2604C22.3142 18.1308 22.5717 18.064 22.8332 18.0668C23.0946 18.0696 23.3506 18.1419 23.5749 18.2763L39.2223 27.729C39.4404 27.8619 39.6207 28.0486 39.7458 28.2713C39.8709 28.494 39.9366 28.7451 39.9366 29.0005C39.9366 29.2559 39.8709 29.507 39.7458 29.7297C39.6207 29.9523 39.4404 30.1391 39.2223 30.272Z',
})




const  AboutUs = ({dir}) => {

    const isDesktop = useBreakpointValue({
        base: false,
        lg: true,
      });


      const [showVideo, setShowVideo] = useState(false);

      const handlePlayVideo = () => {
        setShowVideo(true);
      };
    
      const handleCloseVideo = () => {
        setShowVideo(false);
      };
    
      

      return (
        <>
          <Container
            maxW={'7xl'}
            mx="auto"
            py={12}
            spacing={10}
            mt={5}
          >
            <SimpleGrid columns={{ base: 1, md: 2 }} spacing={4}>
              <Stack gap={0} bgColor={""} pt={{ base: 2, md: 5 }} justifyContent={"start"} alignItems={"left"}>
                <HStack>
                  {/* Language-specific text */}
                  {dir === 'ltr' && (
                    <Text className='Glook' fontSize={{ md: 'md', base: 'sm', }} color={"#ad831d"}>
                      About Us
                    </Text>
                  )}
                  {dir === 'rtl' && (
                    <Text className="cairoR" fontSize={{ md: 'md', base: 'sm', }} color={"#ad831d"}>
                      تعرف علينا
                    </Text>
                  )}
                  <Box position='relative' w='230px'>
                    <Divider border={"20px 20px"} borderColor={"#ad831d"} />
                  </Box>
                </HStack>
    
                {/* Language-specific text */}
                {dir === 'ltr' && (
                  <Text pr={{ base: 2, md: "150px" }} p={0} mt={5} className='Glook' color={"#374151"} fontWeight={600} fontSize={{ base: '2xl', sm: 'xl', lg: '4xl' }}>
                    Alamiriya A <span color='#ad831d'>Construction</span> Company
                  </Text>
                )}
                {dir === 'rtl' && (
                  <Text className="cairoB" pl={{ base: 2, md: "150px" }} p={0} mt={5}  color={"#374151"} fontWeight={600} fontSize={{ base: '2xl', sm: 'xl', lg: '4xl' }}>
                    العامــريـــة شركــة  هندســيـة
                  </Text>
                )}
    
                <Stack>
                  <Stack spacing={6} mt={5} pr={{ base: dir === 'ltr' ? '2' : '', md: dir === 'ltr' ? '150px' : '' }} pl={{ base: dir === 'rtl' ? '2' : '', md: dir === 'rtl' ? '150px' : '' }}>
                    {/* Language-specific text */}
                    {dir === 'ltr' && (
                      <HStack spacing={4} alignItems={"center"}>
                        <Icon as={MdEngineering} color={"#ad831d"} boxSize={10} />
                        <VStack alignItems={"left"} spacing={0}>
                          <Text className='PopB' m={0} fontSize={{ md: 'md', base: 'sm', }} color={'#0b2a49'}>
                            We're in this business since 2022 and We provide the best industrial services
                          </Text>
                        </VStack>
                      </HStack>
                    )}
                    {dir === 'rtl' && (
                      <HStack spacing={4} alignItems={"center"}>
                        <Icon as={MdEngineering} color={"#ad831d"} boxSize={10} />
                        <VStack alignItems={"right"} spacing={0}>
                          <Text className="cairoS" fontWeight={600} m={0} fontSize={{ md: '2xl', base: 'sm', }} color={'#0b2a49'}>
                            نحن في هذا العمل منذ عام 2022 ونقدم أفضل الخدمات الصناعية
                          </Text>
                        </VStack>
                      </HStack>
                    )}
    
                    {/* Language-specific text */}
                    {dir === 'ltr' && (
                      <Stack spacing={4} alignItems={"start"}>
                        <Text className='Pop' fontSize={{ md: 'sm', base: 'sm', }} color={"gray.700"} textAlign={"justify"}>
                          At Alamiriya, We Bring Visions To Life. With A Commitment To Quality Craftsmanship, Innovative Design,
                          And Client Satisfaction, We Are Your Trusted Partner In Construction Excellence. Whether You're Envisioning A
                          New Home, A Commercial Space, Or A Renovation Project, We Have The Expertise To Turn Your Ideas Into Reality.
                        </Text>
                      </Stack>
                    )}
                    {dir === 'rtl' && (
                      <Stack spacing={4} alignItems={"start"}>
                        <Text className='cairoM' fontSize={{ md: 'md', base: 'sm', }} color={"gray.700"} textAlign={"justify"}>
                          في العامرية، نحن نحقق الرؤى. بالتفاني في صنعة الجودة والتصميم المبتكر ورضا العملاء، نحن شريكك الموثوق في التميز في مجال البناء. سواء كنت تتصور منزلًا جديدًا أو مساحة تجارية أو مشروع تجديد، لدينا الخبرة لتحويل أفكارك إلى واقع.
                        </Text>
                      </Stack>
                    )}
    
                    {/* Language-specific text */}
                    {dir === 'ltr' && (
                      <HStack spacing={4} alignItems={"center"} bgColor={""}>
                        <Stack position="relative" alignItems={'center'}>
                          <Image
                            width={'146px'}
                            height={'79px'}
                            alt={'Our Service image'}
                            src={img2}
                            objectFit={'cover'}
                          />
                          <IconButton
                            onClick={handlePlayVideo}
                            isRound
                            position="absolute"
                            top="20px"
                            left="52px"
                            bg="#0b2a49"
                            color="white"
                            p={1}
                            transition="all 0.15s ease"
                            icon={
                              <Icon
                                as={PlayIcon}
                                fontSize="2xl"
                                transition="all 0.15s ease"
                              />
                            }
                            boxShadow="base"
                          />
                        </Stack>
                        <VStack alignItems={"left"} spacing={0}>
                          <Text className='PopM' fontSize={{ md: 'md', base: 'sm', }}>
                            This is how we work on our clients,
                          </Text>
                          {/* <Text className='PopM' fontSize={{ md: 'md', base: 'sm', }}>
                            Watch video
                          </Text> */}
                        </VStack>
                      </HStack>
                    )}
                    {dir === 'rtl' && (
                      <HStack spacing={4} alignItems={"center"} bgColor={""}>
                        <VStack alignItems={"right"} spacing={0}>
                          <Text className='cairoB' fontSize={{ md: 'md', base: 'sm', }}>
                            هكذا نعمل مع عملائنا
                          </Text>
                          {/* <Text className='cairoB' fontSize={{ md: 'md', base: 'sm', }}>
                            شاهد الفيديو
                          </Text> */}
                        </VStack>
                        <Stack position="relative" alignItems={'center'}>
                          <Image
                            width={'146px'}
                            height={'79px'}
                            alt={'Our Service image'}
                            src={img2}
                            objectFit={'cover'}
                          />
                          <IconButton
                            onClick={handlePlayVideo}
                            isRound
                            position="absolute"
                            top="20px"
                            left="52px"
                            bg="#0b2a49"
                            color="white"
                            p={1}
                            transition="all 0.15s ease"
                            icon={
                              <Icon
                                as={PlayIcon}
                                fontSize="2xl"
                                transition="all 0.15s ease"
                              />
                            }
                            boxShadow="base"
                          />
                        </Stack>
                      </HStack>
                    )}
                  </Stack>
                </Stack>
              </Stack>
    
              <Stack bgColor={""} pl={dir === 'ltr' ? '14' : ''} pt={8}>
                {isDesktop && (
                  <Stack ml={8} mt={24} position='relative'
                    _hover={{
                      transform: 'scale(1.05)',
                    }}
                    transition="all 0.15s ease">
    
                    <Image
                      width={'278px'}
                      height={'375px'}
                      alt={'Our Service image'}
                      src={img4}
                      objectFit={'cover'}
                      zIndex={11}
                      position={dir === 'rtl' ? 'absolute' : ''}
                      left={dir === 'rtl' ? '46px' : ''}
                      top={dir === 'rtl' ? '3px' : ''}
                      rounded={'2xl'}

    
                    />
    
                    <Image
                      width={'291px'}
                      height={'372px'}
                      alt={'Our Service image'}
                      src={img3}
                      objectFit={'cover'}
                      position='absolute'
                      zIndex={10}
                      right='46px'
                      top='-69px'
                      rounded={'2xl'}
                    />
                    
    
                  </Stack>
                )}
    
                {!isDesktop && (
                  <Stack ml={8} mt={24} position='relative'
                    _hover={{
                      transform: 'scale(1.05)',
                    }}
                    transition="all 0.15s ease">
    
    
                    <Image
                      width={'291px'}
                      height={'372px'}
                      alt={'Our Service image'}
                      src={img1}
                      objectFit={'cover'}
                      rounded={'2xl'}
                      zIndex={10}
                    />
    
                    <Image
                      width={'278px'}
                      height={'375px'}
                      alt={'Our Service image'}
                      src={img4}
                      objectFit={'cover'}
                      zIndex={1}
                      rounded={'2xl'}
                      position='absolute'
                      right='96px'
                      top='-69px'
                    />
    
    
                  </Stack>
                )}
              </Stack>
    
            </SimpleGrid>
    
            {showVideo && <Video onClose={handleCloseVideo} />}
          </Container>
        </>
      )
    }
    
    export default AboutUs;