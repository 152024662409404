import React from 'react';
import {
  Box,
  Circle,
  Flex,
  HStack,
  Heading,
  Icon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  SimpleGrid,
  Stack,
  StackDivider,
  Text,
  useColorModeValue as mode,
} from '@chakra-ui/react';

import amiriyaVideo from './amiriyaVideo.mp4'

const Video = ({ onClose }) => {
  return (
    <Box height="90vh">
      <Modal isOpen={true} onClose={onClose}  size="4xl" isCentered blockScrollOnMount={false} trapFocus={false}>
        <ModalOverlay />
        <ModalContent borderRadius="xl" mx={{ base: '2.5', lg: '16' }} overflow="hidden">
          <ModalCloseButton top="0" right="0" size="lg" borderRadius="none" borderBottomLeftRadius="md" zIndex={2} />
          <ModalBody bgColor={''} px={{ base: '3', md: '9', lg: '12' }} py={{ base: '5', md: '6', lg: '8' }} pb={{ base: '6' }}>

            
            
            
           
                <Box
                    position={'relative'}
                    // height={'640px'}
                    // rounded={'2xl'}
                    // boxShadow={'2xl'}
                    // width={'848px'}
                    overflow={'hidden'}
                    alignItems={"center"}
                    // bgColor={"#ff0"}
                >
                    <Box
                    as="video"
                    controls
                    src={amiriyaVideo}
                    // poster={Logo}
                    alt="Al-Amiriya Video"
                    objectFit="contain"
                    zIndex={1}
                    
                    />
                </Box>


          </ModalBody>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default Video;