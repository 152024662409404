import { Box, Container, Flex, Heading, IconButton, Img, SimpleGrid, Stack, Text, useColorModeValue as mode, useBreakpointValue } from '@chakra-ui/react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.css';
import { ImQuotesLeft, ImQuotesRight } from 'react-icons/im';
import { ChatMonkey, Plumtic } from './Logo';
import { useState } from 'react';
import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons';
import avAmiriya from './avAmiriya.jpg'


const Testimonial = (props) => {
  const { logo, children,content, contentAr, image, author,authorAr, role, colorScheme: c, dir } = props
  // const accentColor = mode(`${c}.600`, `${c}.400`)
  const accentColor = `${c}`

 
  return (
    <Flex
      direction="column"
      // rounded={{
      //   md: 'lg',
      // }}
      bg={mode('white', 'gray.700')}
      shadow="lg"
      // _hover={{ transform: 'scale(1.02)' }} 
      // transition="all 0.15s ease"
      w={'4xl'}
      // maxHeight={'360px'}
    >
      <Flex
        direction="column"
        position="relative"
        mb="4"
        textAlign="center"
        justify="center"
        align="center"
        pt="10"
        pb="6"
        px="10"
      >
        {/* <Box mb="2">{logo}</Box> */}
        <Box as="blockquote" maxW="360px" mx="auto" my="4">
          <Box
            position="absolute"
            top="6"
            left="5"
            display={{
              base: 'none',
              md: 'inline',
            }}
            fontSize="3xl"
            color={accentColor}
            opacity={0.2}
          >
            <ImQuotesLeft />
          </Box>
          {dir === 'ltr' && 
          <Text className='cairoR' fontSize="lg">{content}</Text>}

          {dir === 'rtl' && 
          <Text className='cairoR' fontSize="lg">{contentAr}</Text>}
          <Box
            position="absolute"
            bottom="-2"
            right="5"
            display={{
              base: 'none',
              md: 'inline',
            }}
            fontSize="3xl"
            color={accentColor}
            opacity={0.2}
          >
            <ImQuotesRight />
          </Box>
        </Box>
      </Flex>
      <Flex
        direction="column"
        position="relative"
        align="center"
        justify="center"
        color="white"
        px="6"
        pb="8"
      >
        <Box
          position="absolute"
          left="0"
          bottom="0"
          w="full"
          h="full"
          // roundedBottom={{
          //   md: 'lg',
          // }}
          overflow="hidden"
          _before={{
            content: `''`,
            display: 'block',
            position: 'absolute',
            bottom: '0',
            left: '-10%',
            width: '120%',
            height: '90%',
            roundedTop: '120%',
            bg: accentColor,
          }}
        />
        <Img
          src={image}
          alt={author}
          rounded="full"
          border="6px solid"
          borderColor={accentColor}
          position="relative"
          mt="-5"
          w="16"
          h="16"
          objectFit="cover"
        />
        <Box position="relative" fontSize="sm" mt="3" textAlign="center">
        {dir === 'ltr' && 
          <Text as="h3" fontWeight="bold" fontSize="md">
            {author}
          </Text>
          }

        {dir === 'rtl' && 
          <Text className='cairoR' as="h3" fontWeight="bold" fontSize="md">
            {authorAr}
          </Text>
          }
          <Text>{role}</Text>
        </Box>
      </Flex>
    </Flex>
  )
}
const TestimonialWithCurve = ({ dir }) => {
  const isDesktop = useBreakpointValue({
    base: false,
    lg: true,
  });
  
  const testimonials = [
    {
      logo: <Plumtic h="6" color="gray.400" />,
      author: "Zaid Al Gabri",
      authorAr: "زيد الجبري",
      role: "Chief Technology Officer, Articlify",
      colorScheme: "#0b2a49",
      image: avAmiriya,
      content: "I appreciate the clear communication channels within Al Amiriya. The updates from the civil engineering team have been timely and informative, contributing to a transparent project management process.",
      contentAr:'أقدر القنوات الواضحة للتواصل داخل شركة العامرية. كانت التحديثات من فريق الهندسة المدنية في الوقت المناسب ومفيدة، مساهمة في عملية إدارة المشروع شفافة.'
    },
    {
      logo: <ChatMonkey h="6" color="gray.400" />,
      author: "Issam BOURASS",
      authorAr: "عصام براس",
      role: "Marketing Manager, Stringify",
      colorScheme: "#ad831d",
      image: avAmiriya,
      content: "Thank you for the wonderful service! The team at Al Amiriya truly excels in designing personalized spaces that seamlessly combine functionality and style. Keep up the excellent work!",
      contentAr:'شكرًا لكم على الخدمة الرائعة! فريق العمل في العامرية يتفوق حقًا في تصميم الأماكن الشخصية التي تجمع بسلاسة بين الوظائف والأناقة. استمروا في العمل الممتاز!'
    },
    {
      // logo: <YourCompanyLogoComponent h="6" color="gray.400" />,  // Replace with your company logo component
      author: "Sarah Ahmed",
      authorAr: "سارة أحمد",
      role: "Customer Relations Specialist",
      colorScheme: "#ad831d",
      image: avAmiriya,
      content: "I wanted to express my gratitude for the outstanding service provided by Al Amiriya. The attention to detail in designing spaces that blend functionality with style is truly commendable.",
      contentAr:'أردت أن أعبر عن امتناني للخدمة الممتازة التي قدمتها العميرية. الانتباه للتفاصيل في تصميم المساحات التي تجمع بين الوظائف والأناقة حقًا مستحق للإشادة.'
    }

    // Add more testimonials as needed
  ];

  const [currentTestimonial, setCurrentTestimonial] = useState(0);

  const handleNext = () => {
    setCurrentTestimonial((prev) => (prev + 1) % testimonials.length);
  };

  const handlePrev = () => {
    setCurrentTestimonial((prev) => (prev - 1 + testimonials.length) % testimonials.length);
  };

  const testimonialStyle = {
    transition: 'transform 0.3s ease',
    transform: `translateX(-${currentTestimonial * 100}%)`, // Adjust as needed
  };

  return (
    <Box as="section" py="12" mt={8}>
      <Container maxW={'7xl'}  as={Stack} spacing={12}>
        <Stack   spacing={0} align={'left'} >
           {/* Anglais */}
           {dir === 'ltr' && 
            <Heading lineHeight={1.1} fontWeight={600} fontSize={{ base: '4xl', sm: '4xl', lg: '6xl' }}>
              <Text className='Glook'  as={'span'} position={'relative'} color={"#0b2a49"} _after={{ content: "''", width: 'full', height: '30%', position: 'absolute', bottom: 1, left: 0, bg: '#ad831d', zIndex: -1, }}>
                 What They Say
              </Text>
            </Heading>
          }

          {/* Arabic */}
          {dir === 'rtl' && 
            <Heading lineHeight={1.1} fontWeight={600} fontSize={{ base: '4xl', sm: '4xl', lg: '6xl' }}>
              <Text className="cairoB"  as={'span'} position={'relative'} color={"#0b2a49"} _after={{ content: "''", width: 'full', height: '30%', position: 'absolute', bottom: 1, left: 0, bg: '#ad831d', zIndex: -1, }}>
                ماذا يقولون عنا   
              </Text>
            </Heading>
          }
           {dir === 'ltr' && 
              <Text className="Pop" mt={4} fontSize={{md: 'md',base: 'sm',}} >We believe that customer feedback is important to help potential customers.</Text>
            }

            {dir === 'rtl' && 
              <Text className="cairoR" mt={4} fontSize={{md: 'md',base: 'sm',}} >نؤمن بأن آراء الزبناء مهمة بالنسبة لزبناءنا المحتملين</Text>
            }
        </Stack>

        <Stack maxW={'7xl'} direction={'row'} align="center" justify="space-between" bgColor={''} mt="4">
         {dir === 'ltr' && 
            <>
              <ChevronLeftIcon boxSize={6} color="#0b2a49" cursor="pointer" onClick={handlePrev}/>
              
                  {isDesktop ? (
                  <>
                    {[0, 1].map((index) => (
                      <Testimonial dir={dir} key={index} {...testimonials[(currentTestimonial + index) % testimonials.length]} />
                    ))}
                  </>
                ) : (
                  
                  <Testimonial dir={dir} key={currentTestimonial} {...testimonials[currentTestimonial]} />
                )}
              <ChevronRightIcon  boxSize={6} color="#0b2a49" cursor="pointer" onClick={handlePrev}/>
              </>
          }

        {dir === 'rtl' && 
            <>
              <ChevronRightIcon boxSize={6} color="#0b2a49" cursor="pointer" onClick={handlePrev}/>
                  {isDesktop ? (
                  <>
                    {[0, 1].map((index) => (
                      <Testimonial dir={dir} key={index} {...testimonials[(currentTestimonial + index) % testimonials.length]} />
                    ))}
                  </>
                ) : (
                  
                  <Testimonial dir={dir} key={currentTestimonial} {...testimonials[currentTestimonial]} />
                )}
              <ChevronLeftIcon  boxSize={6} color="#0b2a49" cursor="pointer" onClick={handlePrev}/>
              </>
          }  

        </Stack>
      </Container>
    </Box>
  )
}

export default TestimonialWithCurve;

