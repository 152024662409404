import { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { Box, ChakraProvider, HStack, Select, Stack, extendTheme, useColorModeValue } from '@chakra-ui/react';
import Home from "./components/pages/home/Home";
import Contact from './components/pages/contact/Contact';
import MotDirecteur from './components/pages/motDirecteur/MotDirecteur';
import ToggleColorMode from './components/ToggleColorMode';
import Blog from './components/elements/blog/Blog';
import Navbar from './components/navbar/Navbar';

function App() {
  const [dir, setDir] = useState("rtl");

  const handleLanguageChange = (newDir) => {
    setDir(newDir);
  };


  return (
    <>
      <ChakraProvider theme={extendTheme({ direction: dir })}>
       
        <Stack m={0} dir={dir}>
          
          <Router>
          <Navbar dir={dir} OnchangeDir={handleLanguageChange}/>
            <Box className="App" m={0}>
              <Routes>
                <Route path='/' element={<Home dir={dir} />} />
                <Route path='/contact' element={<Contact dir={dir} />} />
                <Route path='/construction/:id' element={<Blog />} />
                <Route path='/mot-du-directeur' element={<MotDirecteur dir={dir} />} />
                <Route path="*" element={<Navigate to='/' />} />
              </Routes>
            </Box>
          </Router>
        </Stack>
      </ChakraProvider>
    </>
  );
}

export default App;





// import { useEffect, useState } from 'react'
// import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
// import Home from "./components/pages/home/Home";
// import Contact from './components/pages/contact/Contact';
// import { Box, Center, ChakraProvider, HStack, Stack, Switch, Text, extendTheme } from '@chakra-ui/react';
// import MotDirecteur from './components/pages/motDirecteur/MotDirecteur';
// import ToggleColorMode from './components/ToggleColorMode';
// import TrackOrder from './components/pages/trackOrder/TrackOrder';
// import Blog from './components/elements/blog/Blog';



// function App() {
//   const [dir, setDir] = useState("rtl");

//   return (
//     <>
//       <ChakraProvider theme={extendTheme({ direction: dir })} >
//         <Stack py={4} m={0} bgColor={'#0b2a49'} align={'center'} >
//           <Stack w={'7xl'} bgColor={''} >
//               <HStack>
//                 <Text color={'white'}>Arabic</Text>
//                 <Switch
//                   onChange={(e) => setDir(e.target.checked ? "rtl" : 
//                   "ltr")}
//                 />
//                 <Text color={'white'}>English</Text>
//               </HStack>
//           </Stack>
//         </Stack>
//         <Stack m={0} dir={dir}>
//             <Router>
//               <Box className="App" m={0}>
//                 <Routes>
//                     <Route path='/' element={<Home dir={dir}/>}/>
//                     <Route path='/contact' element={<Contact dir={dir}/>}/>     
//                     <Route path='/makeOrder' element={<TrackOrder/>}/>
//                     <Route path='/construction/:id' element={<Blog />}/>
//                     <Route path='/mot-du-directeur' element={<MotDirecteur dir={dir}/>}/>
//                     <Route path="*" element={<Navigate to='/'/>} />
//                 </Routes>
//               </Box>
//             </Router>
//           </Stack>
//         </ChakraProvider>
//     </>
//   );
// }

// export default App;
