import React, { useState, useEffect } from 'react';

import 'swiper/swiper-bundle.css';

import Footer from '../../elements/Footer/Footer'
import HowItWorks from '../../HowItWorks'
import Hero from './Hero'


import './home.css'
import OurServices from '../../elements/ourServices/OurServices';
import WhyChooseUs from '../../elements/whyChooseUs/WhyChooseUs';
import { Stack} from '@chakra-ui/react';
import TestimonialWithCurve from '../../elements/testimonial/Testimonial';
import AboutUs from '../../elements/aboutUs/AboutUs';
import AccordionLayout from '../../elements/accordion/AccordionLayout';
import AmiriyaNumbers from './amiriyaNumbers/AmiriyaNumbers';





const Home = ({dir}) => {





  return (
    <>

     
          <Hero dir={dir} />
          <HowItWorks dir={dir}/>
          <Stack  bgColor={"#F6F4EE"}>
            <AboutUs dir={dir}/>
            <OurServices dir={dir}/>
            <WhyChooseUs dir={dir}/>
            <AmiriyaNumbers dir={dir}/>
            <TestimonialWithCurve dir={dir}/>
            <AccordionLayout dir={dir}/>
          </Stack>
          <Footer dir={dir}/>

        
    </>
  )
}

export default Home