import { Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Box, Container, Heading, Stack, Text } from "@chakra-ui/react"
import * as React from 'react'

const AccordionLayout = ({dir}) => {
    return (
<Box as="section" py="12" >
    <Container maxW={'7xl'} mx="auto" py={12} spacing={10} 
      >

        <Stack   spacing={0} align={'left'} >
           {/* Anglais */}
           {dir === 'ltr' && 
            <Heading lineHeight={1.1} fontWeight={600} fontSize={{ base: '4xl', sm: '4xl', lg: '6xl' }}>
              <Text className="Glook"  as={'span'} position={'relative'} color={"#0b2a49"} _after={{ content: "''", width: 'full', height: '30%', position: 'absolute', bottom: 1, left: 0, bg: '#ad831d', zIndex: -1, }}>
                FAQ
              </Text>
            </Heading>
          }

          {/* Arabic */}
          {dir === 'rtl' && 
            <Heading lineHeight={1.1} fontWeight={600} fontSize={{ base: '4xl', sm: '4xl', lg: '6xl' }}>
              <Text className="cairoB"  as={'span'} position={'relative'} color={"#0b2a49"} _after={{ content: "''", width: 'full', height: '30%', position: 'absolute', bottom: 1, left: 0, bg: '#ad831d', zIndex: -1, }}>
              الأسئلة المتداولة   
            </Text>
            </Heading>
          }
           {dir === 'ltr' && 
              <Text className="Pop" mt={4} fontSize={{md: 'md',base: 'sm',}} >Explore our comprehensive list of frequently asked questions to find answers to common queries. </Text>
            }

            {dir === 'rtl' && 
              <Text className="cairoR" mt={4} fontSize={{md: 'md',base: 'sm',}} >استكشف قائمتنا الشاملة للأسئلة المتداولة للعثور على إجابات على الاستفسارات الشائعة.</Text>
            }
        </Stack>

        <Stack    align={dir === 'ltr' ? 'left' : 'right'} mx={'auto'} bgColor={''} mt={14}>
            <Accordion defaultIndex={[0]} allowToggle width={{base :'', md:'7xl'}}>
            <AccordionItem  bgColor={'#fff'}>
            <h2>
                <AccordionButton _expanded={{ bg:'#ad831d', color: 'white' }}>
                <Box as="span" className="cairoS" flex='1' textAlign={dir === 'ltr' ? 'left' : 'right'} fontSize={{ base: 'md', md: 'xl' }}>
                    {dir === 'ltr' ? 'What services does your architecture firm provide?' : 'ما هي الخدمات التي يقدمها مكتبكم الهندسي؟'}
                </Box>
                <AccordionIcon fontSize='20px' />
                </AccordionButton>
            </h2>
            <AccordionPanel bgColor={'#12284B'}  color={'white'} pb={4} fontSize={{ md: 'md', base: 'sm' }} className="cairoR">
                {dir === 'ltr' ? 'Our architecture firm provides a wide range of services, including architectural design, construction management, and interior design.' : 'يقدم مكتبنا الهندسي مجموعة واسعة من الخدمات، بما في ذلك التصميم المعماري، وإدارة البناء، وتصميم الديكور الداخلي.'}
            </AccordionPanel>
            </AccordionItem>

{/* 
            <AccordionItem bgColor={'#fff'}>
                <h2>
                <AccordionButton _expanded={{ bg:'#ad831d', color: 'white' }}>
                    <Box as="span" className="cairoS"  flex='1' textAlign={dir === 'ltr' ? 'left' : 'right'}  fontSize={{ base: 'md', md: 'xl' }}  >
                    {dir === 'ltr' ? 'How long have you been in business?' : "منذ متى وأنتم في العمل؟"} 
                    </Box>
                    <AccordionIcon />
                </AccordionButton>
                </h2>
                <AccordionPanel bgColor={'#12284B'}  color={'white'} pb={4} fontSize={{ md: 'md', base: 'sm' }} className="cairoR">
                {dir === 'ltr' ? 'We have been in business for over 25 years.' : 'نحن في الميدان لأكثر من 25 سنة'} 
                
                </AccordionPanel>
            </AccordionItem> */}

            <AccordionItem bgColor={'#fff'}>
            <h2>
                <AccordionButton _expanded={{ bg:'#ad831d', color: 'white' }}>
                <Box as="span" className="cairoS" flex='1' textAlign={dir === 'ltr' ? 'left' : 'right'} fontSize={{ base: 'md', md: 'xl' }}>
                    {dir === 'ltr' ? 'Do you offer consultation services?' : 'هل تقدمون خدمات الاستشارة؟'}
                </Box>
                <AccordionIcon />
                </AccordionButton>
            </h2>
            <AccordionPanel bgColor={'#12284B'}  color={'white'} pb={4} fontSize={{ md: 'md', base: 'sm' }} className="cairoR">
                {dir === 'ltr' ? 'Yes, we offer consultation services to help clients understand their project requirements and come up with the best design solutions.' : 'نعم، نحن نقدم خدمات الاستشارة لمساعدة العملاء في فهم متطلبات مشاريعهم والعثور على أفضل حلول التصميم.'}
            </AccordionPanel>
            </AccordionItem>


            <AccordionItem bgColor={'#fff'}>
            <h2>
                <AccordionButton _expanded={{ bg:'#ad831d', color: 'white' }}>
                <Box as="span" className="cairoS" flex='1' textAlign={dir === 'ltr' ? 'left' : 'right'} fontSize={{ base: 'md', md: 'xl' }}>
                    {dir === 'ltr' ? 'How much do your services cost?' : 'كم تكلف خدماتكم؟'}
                </Box>
                <AccordionIcon />
                </AccordionButton>
            </h2>
            <AccordionPanel bgColor={'#12284B'}  color={'white'} pb={4} fontSize={{ md: 'md', base: 'sm' }} className="cairoR">
                {dir === 'ltr' ? 'Our services vary in cost depending on the scope of the project. Please contact us for more information on pricing.' : 'تتفاوت تكلفة خدماتنا حسب نطاق المشروع. يرجى الاتصال بنا للحصول على مزيد من المعلومات حول التسعير.'}
            </AccordionPanel>
            </AccordionItem>


            <AccordionItem bgColor={'#fff'}>
            <h2>
                <AccordionButton _expanded={{ bg:'#ad831d', color: 'white' }}>
                <Box as="span" className="cairoS" flex='1' textAlign={dir === 'ltr' ? 'left' : 'right'} fontSize={{ base: 'md', md: 'xl' }}>
                    {dir === 'ltr' ? 'What types of projects do you specialize in?' : 'في أي أنواع من المشاريع أنتم متخصصون؟'}
                </Box>
                <AccordionIcon />
                </AccordionButton>
            </h2>
            <AccordionPanel bgColor={'#12284B'}  color={'white'} pb={4} fontSize={{ md: 'md', base: 'sm' }} className="cairoR">
                {dir === 'ltr' ? 'We specialize in residential, commercial, and institutional projects.' : 'نحن متخصصون في المشاريع السكنية والتجارية والمؤسسية.'}
            </AccordionPanel>
            </AccordionItem>

            
            <AccordionItem bgColor={'#fff'}>
            <h2>
                <AccordionButton _expanded={{ bg:'#ad831d', color: 'white' }}>
                <Box as="span" className="cairoS" flex='1' textAlign={dir === 'ltr' ? 'left' : 'right'} fontSize={{ base: 'md', md: 'xl' }}>
                    {dir === 'ltr' ? 'How long does it take to complete a project?' : 'كم يستغرق إكمال مشروع؟'}
                </Box>
                <AccordionIcon />
                </AccordionButton>
            </h2>
            <AccordionPanel bgColor={'#12284B'}  color={'white'} pb={4} fontSize={{ md: 'md', base: 'sm' }} className="cairoR">
                {dir === 'ltr' ? 'The length of time it takes to complete a project depends on the size and scope of the project. Generally, it takes 3 to 6 months to complete a project.' : 'يعتمد مدى الوقت اللازم لإكمال مشروع على حجم ونطاق المشروع. بشكل عام، يستغرق إكمال المشروع من 3 إلى 6 أشهر.'}
            </AccordionPanel>
            </AccordionItem>



            </Accordion>
        </Stack>
    </Container>
</Box>
    )
}

export default AccordionLayout

