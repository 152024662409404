import {
  Box,
  Container,
  Heading,
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
  SimpleGrid,
  Stack,
  Text,
  useBreakpointValue,
} from '@chakra-ui/react'
import * as React from 'react'
import { FiSearch } from 'react-icons/fi'
import { BlogPost } from './BlogPost'
import { posts } from './data'
import Navbar from '../../navbar/Navbar'
import { useParams } from 'react-router-dom';
import { useState } from 'react'


const Blog = () => { 

  const { id } = useParams();

  // const [id, setId] = useState(0);

  // console.log(titleId);

  // React.useEffect(() => {
  // console.log(titleId);
  //   if (titleId === 'residential-construction') {
  //     setId(0);
  //   } else if ( titleId === 'commercial-construction') {
  //     setId(1);
  //   } else {
  //     setId(2);
  //   }
  // }, [titleId]);

  

  return  (
  <>
  <Navbar />
  <Box  >
    <Box bg="#0b2a49" color="on-accent"  >
      <Container pt={{ base: '16', md: '24', }} pb={{ base: '12', md: '20', }} maxW={'7xl'}
      >
        
      </Container>
    </Box>
    <Container pb={{ base: '16', md: '24',}} mt={{ base: '-16', md: '-24', }} maxW={'7xl'}
    >
      <Stack spacing={{ base: '16', md: '24', }}
      >
        <Stack spacing={{ base: '12', md: '16', }}
        >
          <BlogPost post={posts[id]} isHero />
        </Stack>
      </Stack>
    </Container>
  </Box>
  </>
)}


export default Blog