import {
  Box,
  Button,
  ButtonGroup,
  Container,
  Divider,
  IconButton,
  Input,
  Stack,
  Text,
  useColorModeValue,
  Image,
  AspectRatio,
  HStack,
  VStack
} from '@chakra-ui/react'
import * as React from 'react'
import { FaFacebook, FaInstagram, FaLinkedin, FaTwitter, FaWhatsapp, FaYoutube } from 'react-icons/fa'
import LogoEn from './LogoFooterEn.svg';
import LogoAr from './LogoFooterAr.svg';
// import Logo from '../../../assets/amiriyaLogo.svg';

import { FiPhoneCall } from 'react-icons/fi'
import { AiOutlineMail } from 'react-icons/ai'
import { TbDeviceLandlinePhone } from "react-icons/tb";
import { FaX } from 'react-icons/fa6';


const Footer = ({dir}) => {

  console.log('footer', dir);

return (
  <Box bg={'#0b2a49'} color={useColorModeValue('white')}  >
    <Container as="footer" maxW="7xl" role="contentinfo"  >
      <Stack
        spacing="8"
        direction={{
          base: 'column',
          md: 'row',
        }}
        justify="space-between"
        py={{
          base: '12',
          md: '16',
        }}
      >
        <Stack w={{ base: '100%', md: '50%', }} spacing={{ base: '6', md: '8', }} align="start">
        
          <Image src={dir === 'ltr' ? LogoEn : LogoAr}  alt="Logo" height="80px" width="auto"/>
          {/* English */}
          {dir === 'ltr' &&
          <Text className='Pop' style={{ textAlign: 'justify' }}> 
            Ready To Embark On Your Construction Journey?
            Contact Us Today To Discuss Your Project. Whether It's A Dream
            Home, A Modern Office, Or A Transformation Renovation, We're Here To 
            Make It Happen.
          </Text>
          }

           {/* Arabic */}
           {dir === 'rtl' &&
          <Text className='cairoR' style={{ textAlign: 'justify' }}> 
           هل أنت مستعد للشروع في رحلة البناء الخاصة بك؟  اتصل بنا اليوم لمناقشة مشروعك.  سواء كان ذلك منزل الأحلام، أو مكتبًا حديثًا، أو تجديدًا متحولًا، فنحن هنا لتحقيق ذلك
          </Text>
          }

        </Stack>
       <Stack w={{ base: '100%', md: '50%', }}
          direction={{ base: 'column-reverse', md: 'column', lg: 'row',}}
          spacing={{ base: '12', md: '8', }} >

          <Stack direction="row" spacing="8">
            <Stack spacing="4" minW="36" flex="1">
              {/* English */}
              {dir === 'ltr' &&
              <Text fontSize="md" fontWeight="bold" color="#ad831d">
                Contact Information
              </Text>
              }

              {/* Arabic */}
              {dir === 'rtl' &&
              <Text className='cairoM' fontSize="md" fontWeight="bold" color="#ad831d">
                معلومات الإتصال
              </Text>
              }

              <Stack spacing="3" shouldWrapChildren>

                <Button onClick={() => { window.location.href = "tel:+212666150291"; }}
                 leftIcon={<FiPhoneCall fontSize="1.25rem" color="#ad831d" />} variant="outline" color={'white'}   w="full" justifyContent="flex-start" textAlign="left" >
                 {dir === 'ltr' ? '+212 6 43 52 13 82' : '82 13 52 43 6 212+'} 
                </Button>

                <Button onClick={() => { window.location.href = "tel:+212538104249"; }}
                  leftIcon={<TbDeviceLandlinePhone fontSize="1.25rem" color="#ad831d" />} variant="outline" color={'white'} w="full" justifyContent="flex-start" textAlign="left" >
                 {dir === 'ltr' ? '+212 6 43 52 13 82' : '82 13 52 43 6 212+'} 
                </Button>

                <Button onClick={() => { window.location.href = "mailto:alamiriya.eng@gmail.com"; }}
                  leftIcon={<AiOutlineMail fontSize="1.25rem" color="#ad831d" />} variant="outline" color={'white'}  w="full" justifyContent="flex-start" textAlign="left" >
                    alamiriya.eng@gmail.com
                </Button> 

              </Stack>
            </Stack>
            
          </Stack>

          <Stack direction="row" spacing="8">
            
            <Stack spacing="4" minW="45" flex="2">
               {/* English */}
               {dir === 'ltr' &&
               <Text fontSize="md" fontWeight="bold" color="#ad831d">
                Location
               </Text>
               }

               {/* Arabic */}
               {dir === 'rtl' &&
               <Text  className='cairoM' fontSize="md" fontWeight="bold" color="#ad831d">
                  العنوان 
               </Text>
               }
              <Stack  spacing="3" shouldWrapChildren>
                {/* English */}
                {dir === 'ltr' &&
                  <Text className='Pop' style={{ textAlign: 'justify' }}>
                      Morocco, Rabat
                  </Text>
                }

                {/* Arabic */}
                {dir === 'rtl' &&
                  <Text className='cairoR' style={{ textAlign: 'justify' }}>
                     المغرب ، الرباط
                  </Text>
                }

              </Stack>
            </Stack>
            
          </Stack>
          
            
           

        </Stack>
          
      </Stack>
      <Divider borderColor="bg-accent-subtle" />
      <Stack
        pt="8"
        pb="12"
        justify="space-between"
        direction={{
          base: 'column-reverse',
          md: 'row',
        }}
        align="center">
        {/* English */}
        {dir === 'ltr' &&
        <Text fontSize="sm" color="on-accent-subtle">
          &copy; {new Date().getFullYear()} Alamiriya , Inc. Tous droits réservés.
        </Text>
        }

        {/* Arabic */}
        {dir === 'rtl' &&
        <Text fontSize="sm" color="on-accent-subtle">
          العامرية، كل الحقوق محفوظة.  {new Date().getFullYear()} &copy;
        </Text>
        }
        <ButtonGroup variant="ghost-on-accent">
          <IconButton as="a" href="https://www.facebook.com/alamiriya.eng" aria-label="Facebook" icon={<FaFacebook fontSize="1.25rem" />} target="_blank" />
          <IconButton as="a" href="https://www.instagram.com/alamiriya.eng" aria-label="Instagram" icon={<FaInstagram fontSize="1.25rem" />} target="_blank" />
          <IconButton as="a" href="https://wa.me/+212643521382" aria-label="Whatsapp" icon={<FaWhatsapp fontSize="1.25rem" />} target="_blank" />
          <IconButton as="a" href="https://twitter.com/alamiriyaeng" aria-label="Twitter" icon={<FaTwitter fontSize="1.25rem" />} target="_blank" />
          <IconButton as="a" href="https://www.youtube.com/@alamiriyaeng" aria-label="Youtube" icon={<FaYoutube fontSize="1.25rem" />} target="_blank" />
          <IconButton as="a" href="https://www.linkedin.com/company/alamiriya/" aria-label="Youtube" icon={<FaLinkedin fontSize="1.25rem" />} target="_blank" />
        </ButtonGroup>
      </Stack>
    </Container>
  </Box>
)
}
export default Footer;