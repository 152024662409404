import { Box, Container, Heading, SimpleGrid, Stack, Text } from '@chakra-ui/react'
import * as React from 'react'
import { ProductCard } from './ProductCard'
import { products } from './_data'
import { Element } from 'react-scroll'

const AmiriyaNumbers = ({dir}) => (

  <Element name="StatsOnBrand">
  <Container maxW={'7xl'} py={16} as={Stack} spacing={12} >
            <Stack   spacing={0} align={'left'} >
                  {/* Anglais */}
                  {dir === 'ltr' && 
                    <Heading lineHeight={1.1} fontWeight={600} fontSize={{ base: '4xl', sm: '4xl', lg: '6xl' }}>
                      <Text className='Glook'  as={'span'} position={'relative'} color={"#0b2a49"} _after={{ content: "''", width: 'full', height: '30%', position: 'absolute', bottom: 1, left: 0, bg: '#ad831d', zIndex: -1, }}>
                        Our Key Figures
                      </Text>
                    </Heading>
                  }

                  {/* Arabic */}
                  {dir === 'rtl' && 
                    <Heading lineHeight={1.1} fontWeight={600} fontSize={{ base: '4xl', sm: '4xl', lg: '6xl' }}>
                      <Text className="cairoB"  as={'span'} position={'relative'} color={"#0b2a49"} _after={{ content: "''", width: 'full', height: '30%', position: 'absolute', bottom: 1, left: 0, bg: '#ad831d', zIndex: -1, }}>
                        العامرية في أرقام 
                      </Text>
                    </Heading>
                  }

                  {/* Anglais */}
                  {dir === 'ltr' && (
                    <Text className="Pop" mt={4} fontSize={{ md: 'md', base: 'sm' }}>
                      Explore the numbers that define our success and impact.
                    </Text>
                  )}

                  {/* Arabic */}
                  {dir === 'rtl' && (
                    <Text className="cairoR" mt={4} fontSize={{ md: 'md', base: 'sm' }}>
                      استكشف الأرقام التي تحدد نجاحنا وتأثيرنا.
                    </Text>
                  )}
          
    </Stack>
    <SimpleGrid
      columns={{
        base: 1,
        sm: 2,
        md: 3,
        lg: 4,
      }}
      gap={{
        base: '8',
        lg: '10',
      }}
    >
      {products.map((product) => (
        <ProductCard dir={dir} key={product.id} product={product} />
      ))}
    </SimpleGrid>
  </Container>
  </Element>
)


export default AmiriyaNumbers