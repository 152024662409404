import {AiFillCloseCircle } from 'react-icons/ai';
import { ReactNode, useState } from 'react';
import {
  Box,
  Flex,
  Heading,
  Text,
  Stack,
  Container,
  Avatar,
  useColorModeValue,
  Image,
  SimpleGrid,
  Icon,
  IconButton,
  LightMode,
  Button,
} from '@chakra-ui/react';

import TdV from '../../../assets/img/ai/services/3dV.jpg'
import BuildingD from '../../../assets/img/ai/services/BuildingD.jpg'
import ConstructionM from '../../../assets/img/ai/services/ConstructionM.jpg'
import Feasibility from '../../../assets/img/ai/services/Feasibility.jpg'
import InteriorD from '../../../assets/img/ai/services/InteriorD.jpg'
import LandscapeD from '../../../assets/img/ai/services/LandscapeD.jpg'
import {  Element } from 'react-scroll';
import { GoArrowUpRight, GoArrowRight } from "react-icons/go";
import { CgDollar } from "react-icons/cg";


import { Link } from 'react-router-dom';





const Testimonial = ({ children }) => {
  return <Box>{children}</Box>;
};

const TestimonialContent = ({ children }) => {
  return (
    <Stack
      bg={useColorModeValue('white', 'gray.800')}
      boxShadow={'lg'}
      p={8}
      // rounded={'xl'}
      align={'center'}
      pos={'relative'}
      // borderRadius="2xl" 
      borderTop="8px" 
      borderColor="#0b2a49"
      _after={{
        content: `""`,
        w: 0,
        h: 0,
        borderLeft: 'solid transparent',
        borderLeftWidth: 16,
        borderRight: 'solid transparent',
        borderRightWidth: 16,
        borderTop: 'solid',
        borderTopWidth: 16,
        borderTopColor: useColorModeValue('white', 'gray.800'),
        pos: 'absolute',
        bottom: '-16px',
        left: '50%',
        transform: 'translateX(-50%)',
      }}
      zIndex={20}
      >{children}
    </Stack>
  );
};

const TestimonialHeading = ({ children }) => {
  return (
    <Text className="cairoB" fontSize={'xl'} fontWeight={600} color={"#ad831d"}>
      {children}
    </Text>
  );
};

const TestimonialText = ({ children }) => {
  return (
    <Text
      className="cairoR"
      textAlign={'center'}
      color={useColorModeValue('gray.600', 'gray.400')}
      fontSize={'sm'}>
      {children}
    </Text>
  );
};




export default function OurServices({dir}) {

  const [isHovered1, setIsHovered1] = useState(false);
  const [isHovered2, setIsHovered2] = useState(false);
  const [isHovered3, setIsHovered3] = useState(false);

  const TestimonialIcon = ({ icon, link, isHovered, setIsHovered }) => {
    return (
      <Link to={link}>
        <IconButton
          isRound
          position="absolute"
          bottom="4"
          left="4"
          bg="#ad831d"
          color="white"
          size="5xl"
          px={3} py={2}
          _hover={{
            bg: 'white',
            color: '#ad831d',
          }}
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
          transition="all 0.15s ease"
          icon={
            <Icon
              as={isHovered ? GoArrowUpRight : GoArrowRight}
              fontSize="3xl"
              transition="all 0.15s ease"
            />
          }
          boxShadow="base"
        />
      </Link>
    );
  };

 

  
  return (
    <Element name="OurServices">
    <Box  >
      <Container maxW={'7xl'} py={16} as={Stack} spacing={12}  mt={dir === 'rtl' ? 4 : 0} >
        <Stack   spacing={0} align={'left'} >

        {/* Anglais */}
        {dir === 'ltr' && 
          <Heading lineHeight={1.1} fontWeight={600} fontSize={{ base: '4xl', sm: '4xl', lg: '6xl' }}
          >
            <Text className='Glook'  as={'span'} position={'relative'} color={"#0b2a49"} _after={{ content: "''", width: 'full', height: '30%', position: 'absolute', bottom: 1, left: 0, bg: '#ad831d', zIndex: -1, }}>
             Services
            </Text>
          </Heading>
        }
        {/* Arabic */}
        {dir === 'rtl' && 
          <Heading lineHeight={1.1} fontWeight={600} fontSize={{ base: '4xl', sm: '4xl', lg: '6xl' }}>
            <Text className='cairoB'  as={'span'} position={'relative'} color={"#0b2a49"} _after={{ content: "''", width: 'full', height: '30%', position: 'absolute', bottom: 1, left: 0, bg: '#ad831d', zIndex: -1, }}>
             خدماتنا
            </Text>
          </Heading>
        }
          
          
        {/* Anglais */}
        {dir === 'ltr' && 
        <Text className="Pop" mt={4} fontSize={{md: 'md',base: 'sm',}} >Revitalize Your Space With Our Renovation And Remodeling Services</Text>
        }

        {/* Arabic */}
        {dir === 'rtl' && 
        <Text className="cairoR" mt={4} fontSize={{md: 'md',base: 'sm',}} >أحيِ نطاقك مع خدمات التجديد والترميم الخاصة بنا</Text>
        }
        </Stack>
        <SimpleGrid
            columns={{base:"1", md:"3"}} // Display two values in a row
            spacing={5}
            // bgColor={"red"}
            maxW={"7xl"}
            
          >
          <Testimonial>
            <TestimonialContent>

              {/* English */}
              {dir === 'ltr' && (
                <TestimonialHeading>Building Design</TestimonialHeading>
              )}

              {/* Arabic */}
              {dir === 'rtl' && (
                <TestimonialHeading>تصميم المباني</TestimonialHeading>
              )}

              {/* English */}
              {dir === 'ltr' && (
                <TestimonialText>
                  Designing a personalized and aesthetically pleasing space to create a unique living or workspace involves blending functionality with style.
                </TestimonialText>
              )}

              {/* Arabic */}
              {dir === 'rtl' && (
                <TestimonialText>
                  تصميم مساحة شخصية وجمالية لإنشاء مكان معيشة أو عمل فريد يتضمن مزج الوظائف بأسلوب جذاب.
                </TestimonialText>
              )}

            </TestimonialContent>

            <Flex align={'center'} direction={'column'}>
              <Stack spacing={-1} align={'center'} position="relative">
                <Image
                  // boxSize='100px' 
                  objectFit='cover' src={BuildingD} alt='residential'
                />

                {/* <TestimonialIcon link="/construction/0" isHovered={isHovered1} setIsHovered={setIsHovered1} /> */}
                {/* <Button position="absolute" bottom="0" left="0" bg="#ad831d" borderRadius={'0px'} color="white" size="5xl" px={3} py={2} rightIcon={<CgDollar />}  variant='outline'>100</Button> */}
               
              </Stack>
            </Flex>

          </Testimonial>

          
          <Testimonial>
            <TestimonialContent>
              
              {/* English */}
              {dir === 'ltr' && (
                <TestimonialHeading>Interior Design</TestimonialHeading>
              )}

              {/* Arabic */}
              {dir === 'rtl' && (
                <TestimonialHeading>تصميم الديكور الداخلي</TestimonialHeading>
              )}

              {/* English */}
              {dir === 'ltr' && (
                <TestimonialText>
                  We offer interior design services that combine functionality with modern style and unique personal touches to create a home or office of your dreams.
                </TestimonialText>
              )}

              {/* Arabic */}
              {dir === 'rtl' && (
                <TestimonialText>
                  نقدم خدمات تصميم الديكور الداخلي التي تجمع بين الوظائف والأناقة الحديثة واللمسات الشخصية الفريدة لإنشاء منزل أو مكتب أحلامك.
                </TestimonialText>
              )}

            </TestimonialContent>

            {/* <TestimonialAvatar/> */}
            <Flex align={'center'} direction={'column'}>
              <Stack spacing={-1} align={'center'} position="relative">
                <Image
                  // boxSize='100px' 
                  objectFit='cover' src={InteriorD} alt='commercial'
                />
                {/* <TestimonialIcon link="/construction/1" isHovered={isHovered2} setIsHovered={setIsHovered2} /> */}
                 


              </Stack>
            </Flex>

          </Testimonial>



          <Testimonial>
          <TestimonialContent>

            {/* English */}
            {dir === 'ltr' && (
              <TestimonialHeading>Landscape Design</TestimonialHeading>
            )}

            {/* Arabic */}
            {dir === 'rtl' && (
              <TestimonialHeading>تصميم الطوابق</TestimonialHeading>
            )}

            {/* English */}
            {dir === 'ltr' && (
              <TestimonialText>
                With our landscape design services, we provide professional consultations and detailed project plans to create beautiful and functional outdoor spaces.
              </TestimonialText>
            )}

            {/* Arabic */}
            {dir === 'rtl' && (
              <TestimonialText>
                مع خدمات تصميم الطابوق الخاصة بنا، نقدم استشارات متخصصة وخطط مشاريع مفصلة لإنشاء مساحات خارجية جميلة وعملية.
              </TestimonialText>
            )}

          </TestimonialContent>

          {/* <TestimonialAvatar/> */}
          <Flex align={'center'} direction={'column'}>
            <Stack spacing={-1} align={'center'} position="relative">
              <Image
                // boxSize='100px' 
                objectFit='cover' src={LandscapeD} alt='commercial'
              />
              {/* <TestimonialIcon link="/construction/2" isHovered={isHovered3} setIsHovered={setIsHovered3} /> */}
               

            </Stack>
          </Flex>

        </Testimonial>



        <Testimonial>
          <TestimonialContent>

            {/* English */}
            {dir === 'ltr' && (
              <TestimonialHeading>Feasibility Studies</TestimonialHeading>
            )}

            {/* Arabic */}
            {dir === 'rtl' && (
              <TestimonialHeading>دراسات الجدوى</TestimonialHeading>
            )}

            {/* English */}
            {dir === 'ltr' && (
              <TestimonialText>
                This website evaluates all aspects of potential architecture projects, giving customers the confidence to make informed decisions.
              </TestimonialText>
            )}

            {/* Arabic */}
            {dir === 'rtl' && (
              <TestimonialText>
                يقوم هذا الموقع بتقييم جميع جوانب المشاريع المعمارية المحتملة، مما يمنح العملاء الثقة لاتخاذ قرارات مستنيرة.
              </TestimonialText>
            )}

          </TestimonialContent>

          {/* <TestimonialAvatar/> */}
          <Flex align={'center'} direction={'column'}>
            <Stack spacing={-1} align={'center'} position="relative">
              <Image
                // boxSize='100px' 
                objectFit='cover' src={Feasibility} alt='commercial'
              />
              {/* <TestimonialIcon link="/construction/2" isHovered={isHovered3} setIsHovered={setIsHovered3} />
               */}
                 

            </Stack>
          </Flex>

        </Testimonial>


        <Testimonial>
          <TestimonialContent>

            {/* English */}
            {dir === 'ltr' && (
              <TestimonialHeading>Construction Management</TestimonialHeading>
            )}

            {/* Arabic */}
            {dir === 'rtl' && (
              <TestimonialHeading>إدارة البناء</TestimonialHeading>
            )}

            {/* English */}
            {dir === 'ltr' && (
              <TestimonialText>
                Architecture firm offers comprehensive construction management services to seamlessly develop projects from concept to completion.
              </TestimonialText>
            )}

            {/* Arabic */}
            {dir === 'rtl' && (
              <TestimonialText>
                 نقدم خدمات شاملة لإدارة البناء بشكل فعال لتطوير المشاريع بسلاسة من المفهوم إلى الاكتمال.
              </TestimonialText>
            )}

          </TestimonialContent>

          {/* <TestimonialAvatar/> */}
          <Flex align={'center'} direction={'column'}>
            <Stack spacing={-1} align={'center'} position="relative">
              <Image
                // boxSize='100px' 
                objectFit='cover' src={ConstructionM} alt='commercial'
              />
              {/* <TestimonialIcon link="/construction/2" isHovered={isHovered3} setIsHovered={setIsHovered3} /> */}

            </Stack>
          </Flex>

        </Testimonial>


        <Testimonial>
        <TestimonialContent>

          {/* English */}
          {dir === 'ltr' && (
            <TestimonialHeading>3D Visualization</TestimonialHeading>
          )}

          {/* Arabic */}
          {dir === 'rtl' && (
            <TestimonialHeading>تصور ثلاثي الأبعاد</TestimonialHeading>
          )}

          {/* English */}
          {dir === 'ltr' && (
            <TestimonialText>
              Our 3D visualization offerings provide a unique perspective of spaces and structures to bring designs to life.
            </TestimonialText>
          )}

          {/* Arabic */}
          {dir === 'rtl' && (
            <TestimonialText>
              تقدم خدمات التصور ثلاثي الأبعاد لدينا منظورًا فريدًا للمساحات والهياكل لإحياء التصاميم.
            </TestimonialText>
          )}

        </TestimonialContent>

        {/* <TestimonialAvatar/> */}
        <Flex align={'center'} direction={'column'}>
          <Stack spacing={-1} align={'center'} position="relative">
            <Image
              // boxSize='100px' 
              objectFit='cover' src={TdV} alt='commercial'
            />
            {/* <TestimonialIcon link="/construction/2" isHovered={isHovered3} setIsHovered={setIsHovered3} /> */}

          </Stack>
        </Flex>

      </Testimonial>

          
        </SimpleGrid>
      </Container>
    </Box>
    </Element>
  );
}




// import {AiFillCloseCircle } from 'react-icons/ai';
// import { ReactNode, useState } from 'react';
// import {
//   Box,
//   Flex,
//   Heading,
//   Text,
//   Stack,
//   Container,
//   Avatar,
//   useColorModeValue,
//   Image,
//   SimpleGrid,
//   Icon,
//   IconButton,
//   LightMode,
// } from '@chakra-ui/react';

// import com from '../../../assets/img/ai/commercial.jpg'
// import res from '../../../assets/img/ai/residential.jpg'
// import {  Element } from 'react-scroll';
// import { GoArrowUpRight, GoArrowRight } from "react-icons/go";

// import { Link } from 'react-router-dom';





// const Testimonial = ({ children }) => {
//   return <Box>{children}</Box>;
// };

// const TestimonialContent = ({ children }) => {
//   return (
//     <Stack
//       bg={useColorModeValue('white', 'gray.800')}
//       boxShadow={'lg'}
//       p={8}
//       // rounded={'xl'}
//       align={'center'}
//       pos={'relative'}
//       // borderRadius="2xl" 
//       borderTop="8px" 
//       borderColor="#0b2a49"
//       _after={{
//         content: `""`,
//         w: 0,
//         h: 0,
//         borderLeft: 'solid transparent',
//         borderLeftWidth: 16,
//         borderRight: 'solid transparent',
//         borderRightWidth: 16,
//         borderTop: 'solid',
//         borderTopWidth: 16,
//         borderTopColor: useColorModeValue('white', 'gray.800'),
//         pos: 'absolute',
//         bottom: '-16px',
//         left: '50%',
//         transform: 'translateX(-50%)',
//       }}
//       zIndex={20}
//       >{children}
//     </Stack>
//   );
// };

// const TestimonialHeading = ({ children }) => {
//   return (
//     <Text className="Pop" fontSize={'xl'} fontWeight={600} color={"teal"}>
//       {children}
//     </Text>
//   );
// };

// const TestimonialText = ({ children }) => {
//   return (
//     <Text
//       className="Pop"
//       textAlign={'center'}
//       color={useColorModeValue('gray.600', 'gray.400')}
//       fontSize={'sm'}>
//       {children}
//     </Text>
//   );
// };




// export default function OurServices() {

//   const [isHovered, setIsHovered] = useState(false);

  
//   return (
//     <Element name="OurServices">
//     <Box  >
//       <Container maxW={'7xl'} py={16} as={Stack} spacing={12} >
//         <Stack   spacing={0} align={'left'} >
//           <Heading lineHeight={1.1} fontWeight={600} fontSize={{ base: '4xl', sm: '4xl', lg: '6xl' }}>
//             <Text
//               className='Glook' 
//               as={'span'}
//               position={'relative'}
//               color={"#0b2a49"}
//               _after={{
//                 content: "''",
//                 width: 'full',
//                 height: '30%',
//                 position: 'absolute',
//                 bottom: 1,
//                 left: 0,
//                 bg: '#ad831d',
//                 zIndex: -1,
//               }}
//             >
//               Our Services
//             </Text>
//           </Heading>
//           <Text className="Pop" fontSize={{md: 'md',base: 'sm',}} >Revitalize Your Space With Our Renovation And Remodeling Services</Text>
//         </Stack>
//         <SimpleGrid
//             columns={{base:"1", md:"3"}} // Display two values in a row
//             spacing={5}
//             // bgColor={"red"}
//             maxW={"7xl"}
            
//           >
//           <Testimonial >
//             <TestimonialContent>
//               <TestimonialHeading >Residential Construction</TestimonialHeading>
//               <TestimonialText>
//               Crafting dream homes with precision and care, our Residential Construction services prioritize quality craftsmanship and tailored solutions, 
//               ensuring a seamless journey from concept to completion.
//               </TestimonialText>
//             </TestimonialContent>


//             <Flex align={'center'}  direction={'column'}>
//               <Stack spacing={-1} align={'center'} position="relative">
//               <Image 
//               // boxSize='100px' 
//               objectFit='cover' src={res} alt='residential'
//               />

//                   <Link to={`/blog`}>
//                   <IconButton
//                       isRound
//                       position="absolute"
//                       bottom="4"
//                       left="4"
//                       bg="#ad831d"
//                       color="white"
//                       size="5xl"
//                       px={3} py={2}
//                       _hover={{
//                         // transform: 'scale(1.1)',
//                         bg: 'white',
//                         color: '#ad831d',
//                       }}
//                       onMouseEnter={() => setIsHovered(true)}
//                       onMouseLeave={() => setIsHovered(false)}
//                       transition="all 0.15s ease"
//                       icon={
//                         <Icon
//                           as={isHovered ? GoArrowUpRight : GoArrowRight}
//                           fontSize="3xl"
//                           transition="all 0.15s ease"
//                         />
//                       }
//                       boxShadow="base"
//                     />
//                   </Link>
//               </Stack>
//             </Flex>

//           </Testimonial>
          

//           <Testimonial>
//             <TestimonialContent>
//               <TestimonialHeading>Commercial Construction</TestimonialHeading>
//               <TestimonialText>
//               In the realm of Commercial Construction, we specialize in innovative solutions, managing large-scale 
//               projects with efficiency to create functional and aesthetically pleasing spaces that elevate your business environment.
//               </TestimonialText>
//             </TestimonialContent>

//             {/* <TestimonialAvatar/> */}
//             <Flex align={'center'}  direction={'column'}>
//               <Stack spacing={-1} align={'center'} position="relative">
//               <Image 
//               // boxSize='100px' 
//               objectFit='cover' src={com} alt='commercial'
//               />
//                     <Link to={`/blog`}>
//                       <IconButton
//                         isRound
//                         position="absolute"
//                         bottom="4"
//                         left="4"
//                         bg="#ad831d"
//                         color="white"
//                         size="5xl"
//                         px={3} py={2}
//                         _hover={{
//                           // transform: 'scale(1.1)',
//                           bg: 'white',
//                           color: '#ad831d',
//                         }}
//                         onMouseEnter={() => setIsHovered(true)}
//                         onMouseLeave={() => setIsHovered(false)}
//                         transition="all 0.15s ease"
//                         icon={
//                           <Icon
//                             as={isHovered ? GoArrowUpRight : GoArrowRight}
//                             fontSize="3xl"
//                             transition="all 0.15s ease"
//                           />
//                         }
//                         boxShadow="base"
//                       />
//                     </Link>
//               </Stack>
//             </Flex>

//           </Testimonial>


//           <Testimonial>
//             <TestimonialContent>
//               <TestimonialHeading>Commercial Construction</TestimonialHeading>
//               <TestimonialText>
//               In the realm of Commercial Construction, we specialize in innovative solutions, managing large-scale 
//               projects with efficiency to create functional and aesthetically pleasing spaces that elevate your business environment.
//               </TestimonialText>
//             </TestimonialContent>

//             {/* <TestimonialAvatar/> */}
//             <Flex align={'center'}  direction={'column'}>
//               <Stack spacing={-1} align={'center'} position="relative">
//               <Image 
//               // boxSize='100px' 
//               objectFit='cover' src={com} alt='commercial'
//               />
                    
//                     <Link to={`/blog`}>
//                       <IconButton
//                           isRound
//                           position="absolute"
//                           bottom="4"
//                           left="4"
//                           bg="#ad831d"
//                           color="white"
//                           size="5xl"
//                           px={3} py={2}
//                           _hover={{
//                             // transform: 'scale(1.1)',
//                             bg: 'white',
//                             color: '#ad831d',
//                           }}
//                           onMouseEnter={() => setIsHovered(true)}
//                           onMouseLeave={() => setIsHovered(false)}
//                           transition="all 0.15s ease"
//                           icon={
//                             <Icon
//                               as={isHovered ? GoArrowUpRight : GoArrowRight}
//                               fontSize="3xl"
//                               transition="all 0.15s ease"
//                             />
//                           }
//                           boxShadow="base"
//                         />
//                     </Link>
//               </Stack>
//             </Flex>

//           </Testimonial>

          
//         </SimpleGrid>
//       </Container>
//     </Box>
//     </Element>
//   );
// }

