

import {
  AspectRatio,
  Box,
  Circle,
  HStack,
  Heading,
  Image,
  Link,
  Skeleton,
  Stack,
  Tag,
  Text,
  useColorModeValue,
} from '@chakra-ui/react'
import * as React from 'react'
import { Rating } from './Rating'
import { PriceTag } from './PriceTag'
import { ProductButtonGroup } from './ProductButtonGroup'

export const ProductCard = (props) => {
  const { product, dir } = props
  return (
    <Stack spacing="3">
      {/* transition="transform .6s cubic-bezier(.24,1,.3,1),opacity .55s ease .25s" */}
      {/* transition="transform 1s ease,opacity .5s ease .25s" */}
      <Link _hover={{ textDecor: 'none', transform: 'translateY(-2%)',  }} transition="transform .6s cubic-bezier(.24,1,.3,1),opacity .55s ease .25s"  role="group" order={{ base: 2, md: 1 }}>
         <Box position="relative" overflow="hidden"> 
          <AspectRatio ratio={3 / 4}>
            <Image
              src={product.imageUrl}
              alt='img'
              draggable="false"
              fallback={<Skeleton />}
              transition="all 5s"
              _groupHover={{
                  transform: 'scale(1.1)',
              }}
            />
          </AspectRatio>
          <Box
                opacity="1"
                transition="opacity 0.1s"
                _groupHover={{
                  opacity: 0,
                }}
                position="absolute"
                inset="0"
                bgGradient="linear(to-b, transparent 40%,   #000 )"
                boxSize="full"
              />


          <Box
                opacity="0"
                transition="opacity 0.1s"
                _groupHover={{
                  opacity: 1,
                }}
                position="absolute"
                inset="0"
                bgGradient="linear(to-b, transparent 40%,   #bd9b70)"
                // bgGradient="linear-gradient(to bottom,rgba(0,0,0,0),#bd9b70 100%)"
                boxSize="full"
              />

          <Stack m={0}  position="absolute" bottom="0" left="3" right="3" align={'center'}>
                      
            {/* <Stack spacing="3" textAlign="center" >
              <Heading
                color="white"
                fontSize="6xl" fontWeight="extrabold"
              >
                30
              </Heading>
              <Text as={'h2'} className="cairoB" fontSize="4xl" fontWeight="medium" color="white">
                مليون زائر  
              </Text>
              <Text
                as={'h2'}
                className="cairoR"
                fontSize="xl"
                fontWeight="medium"
                color="white"
                opacity="0"
                transition="opacity 0.3s, transform 0.3s"
                _groupHover={{
                  opacity: 1,
                  transform: 'translateY(-50%)',
                }}
              >
                مستهدف بحلول عام 2030
              </Text>

            </Stack> */}

            <Box
              opacity="1"
              transition="opacity 0.3s, transform 0.3s"
              _groupHover={{
                opacity: 1,
                transform: 'translateY(-15%)',
              }}
              // bgColor={'red.200'}
              align={'center'}
              m={0}
            >
              <Heading color="white" fontSize="6xl" fontWeight="extrabold">
                  {product.value}
              </Heading>
              <Text
                as={'h2'}
                className="cairoB"
                fontSize="2xl"
                fontWeight="medium"
                color="white"
              >
                  {dir === 'ltr' ? `${product.text}` : `${product.textAr}`}
              </Text>
            </Box>
            <Text
              as={'h2'}
              className="cairoR"
              fontSize="xl"
              fontWeight="medium"
              color="white"
              opacity="0"
              transition="opacity 0.3s, transform 0.3s"
              _groupHover={{
                opacity: 1,
                transform: 'translateY(-50%)',
              }}
              // bgColor={'green.300'}
            >
              {dir === 'ltr' ? `${product.subText}` : `${product.subTextAr}`}
            </Text>
          </Stack>
        </Box>
      </Link>
     
    </Stack>
  )
}
