import img1 from './accid.jpg'
import img2 from './clients.jpg'
import img3 from './partner.jpg'
import img4 from './prj.jpg'

export const products = [
  {
    id: '1',
    value: '3',
    text: 'Clients',
    textAr: 'الزبناء',
    subText: 'Satisfied with our services',
    subTextAr: 'راضون عن خدماتنا',
    imageUrl: img2
  },
  {
    id: '2',
    value: '0',
    text: 'Partners',
    textAr: 'الشركاء',
    subText: 'together towards the better',
    subTextAr: 'يدا في يد نحو الأفضل',
    imageUrl:img3
    },
  {
    id: '3',
    value: '0',
    text: 'Projects Completed',
    textAr: 'مشروع منجز',
    subText: 'with Precision and excellence',
    subTextAr: 'بدقة وحرفية عالية',
    imageUrl: img4
    },
  {
    id: '4',
    value: '0',
    text: 'Workplace Accidents',
    textAr: 'حوادث الشغل',
    subText: 'Safety is our top priority',
    subTextAr: 'السلامة هي أولويتنا الرئيسية',
    imageUrl: img1
  },
]
