
import {
  Box,
  Button,
  Flex,
  HStack,
  IconButton,
  Image,
  useBreakpointValue,
  useColorModeValue,
  Container,
  ChakraProvider,
  extendTheme,
  Text
} from '@chakra-ui/react';
import * as React from 'react';
import { FiMenu, FiX } from 'react-icons/fi';
import MobileMenu from './MobileMenu';
import { Link, useLocation  } from 'react-router-dom';
import Logo from '../../assets/logoEn.svg';
import LogoAr from '../../assets/logoAr.svg';
import { Link as LinkScroll } from 'react-scroll';
import { useColorMode } from "@chakra-ui/color-mode";







const Navbar = ({ dir, OnchangeDir }) => {
  
  const isDesktop = useBreakpointValue({
    base: false,
    lg: true,
  });

  const [direction, setDirection] = React.useState('ltr');  // Initial direction is left-to-right (ltr)

  const handleLanguageChange = () => {
    // Toggle between 'ltr' and 'rtl' on each click
    setDirection((prevDir) => (prevDir === 'ltr' ? 'rtl' : 'ltr'));
    OnchangeDir(direction);
  };


  const { colorMode, toggleColorMode } = useColorMode();

  const location = useLocation();
  const isContactOrDirectorPath = location.pathname === '/contact' || location.pathname === '/mot-du-directeur' || location.pathname === '/construction/0' || location.pathname === '/construction/1' || location.pathname === '/construction/2';


  const [isScrolled, setIsScrolled] = React.useState(false);

  const handleScroll = () => {
    if (window.scrollY > 0) {
      setIsScrolled(true);
    } else {
      setIsScrolled(false);
    }
  };

  React.useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const [isMenuOpen, setIsMenuOpen] = React.useState(false);

  const handleMenuToggle = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const bgColor =
  isScrolled && colorMode === 'dark'
    ?  'rgba(26, 32, 44, 0.8)' // Adjust the RGB values and the alpha value for the desired color and transparency
    : isScrolled
    ? 'rgba(255, 255, 255, 0.8)'
    : 'transparent';

  return (

    <Box
      as="nav"
      position="sticky"
      top="0"
      zIndex="100"
      // bg={isScrolled ? 'rgba(255, 255, 255, 0.8)' : 'transparent'}
      // bg={isScrolled && colorMode === 'dark' ? 'blue.900' : isScrolled ? 'rgba(255, 255, 255, 0.8)' : 'transparent'}
      bg={bgColor}

      backdropFilter={isScrolled ? 'blur(4px)' : 'none'}
      transition="all 0.3s ease"
      boxShadow={isScrolled ? 'sm' : 'none'}
    >
      <Container as="section" maxW="7xl" bgColor={''} py={{ base: '3', lg: '4' }}>
        <Flex justify="space-between" align="center">
          
          <HStack spacing={4}>
            <Link to="/" aria-label="Home">
              <Image src={dir === 'ltr' ? Logo : LogoAr} alt="Logo" height="16" width="auto" />
            </Link>
          </HStack>
           {isDesktop ? (
            <HStack spacing={4}>
              {!isContactOrDirectorPath && (
                <>
                  <LinkScroll to="OurServices" aria-label="OurServices" spy={true} smooth={true} offset={-70} duration={2000}>
                    <Button className='cairoR' variant={'solid'}  color={"#fff"} bgColor={"#ad831d"} _hover={{ bg: '#8d671c', }}  borderRadius='0'>
                      {dir === 'ltr' ? 'Services' : 'خدماتنا'}
                    </Button>
                  </LinkScroll>
                  <LinkScroll to="StatsOnBrand" aria-label="StatsOnBrand" spy={true} smooth={true} offset={-70} duration={2000}>
                    <Button className='cairoR' variant={'solid'} bgColor={"#0b2a49"} _hover={{bg: '#051d30', }}  color={'#fff'}  borderRadius='0'>
                      {dir === 'ltr' ? 'Our Key Figures' : 'العامرية في أرقام'}
                    </Button>
                  </LinkScroll>
                  <Link to={`/contact`}>
                  <Button className='cairoR' color={"white"} bgColor={'#AFB5BF'} _hover={{ bg: '#959AA0' }}  borderRadius='0'>
                       {dir === 'ltr' ? 'Contact Us' : 'راسلنا'}
                  </Button>
                  </Link>
{/*                   
                  <Button onClick={() => toggleColorMode()}  >
                    {colorMode === "dark" ? (
                      <SunIcon color="orange.200" />
                    ) : (
                      <MoonIcon color="blue.700" />
                    )}
                  </Button> */}


            <Button variant={'outline'} bgColor={'white'} onClick={handleLanguageChange}>
              {direction === 'ltr' ? (
                // <FaFlagUsa color="blue.700" />
                <Text className='cairoR'>الإنجليزية</Text>
              ) : (
                // <GiArabicDoor color="orange.200" />
                <Text className='cairoR'>Arabic</Text>

              )}
            </Button>


                </>
              )}
              {isContactOrDirectorPath && (
                <>
                
                <Link to={`/mot-du-directeur`}>
                  <Button className='cairoR' variant={'solid'} bgColor={"#0b2a49"} _hover={{bg: '#051d30', }}  color={'#fff'}  borderRadius='0' >
                    {dir === 'ltr' ? 'Word from the Executive Director' : 'كلمة المدير العام' }
                  </Button>
                </Link>
                <Link to={`/contact`}>
                  <Button className='cairoR' color={"white"} bgColor={'#AFB5BF'} _hover={{ bg: '#959AA0' }}  borderRadius='0'>
                     {dir === 'ltr' ? 'Contact Us' : 'راسلنا'}
                  </Button>
                  </Link>

                 
                  <Button variant={'outline'} bgColor={'white'} onClick={handleLanguageChange}>
              {direction === 'ltr' ? (
                // <FaFlagUsa color="blue.700" />
                <Text className='cairoR'>الإنجليزية</Text>
              ) : (
                // <GiArabicDoor color="orange.200" />
                <Text className='cairoR'>Arabic</Text>

              )}
            </Button>

              </>
                
              )}
            </HStack>
          ) : (
            <IconButton
              variant="ghost"
              icon={isMenuOpen ? <FiX fontSize="1.25rem" /> : <FiMenu fontSize="1.25rem" />}
              aria-label="Toggle Menu"
              onClick={handleMenuToggle}
            />
          )}
        </Flex>
        {isMenuOpen && (
          <Box mt={4}>
            <MobileMenu dir={dir} OnchangeDir={OnchangeDir}/>
          </Box>
        )}
      </Container>
    </Box>
  );
};

export default Navbar;
