import React from 'react'
import {
    Container,
    SimpleGrid,
    Image,
    Link,
    Heading,
    Text,
    Stack,
    StackDivider,
    Icon,
    useBreakpointValue,
    HStack,
    VStack,
    Box,
    Divider,
  } from '@chakra-ui/react';
  import img from '../../../assets/img/ai/chooseUs.jpg'
  import img2 from '../../../assets/img/ai/img2.jpg'
import SocialButtonGroup from './SocialButtonGroup';
import { FcFactoryBreakdown } from "react-icons/fc";
import { MdMoreTime } from "react-icons/md";
import { MdEngineering } from "react-icons/md";
import { PiHandshake } from "react-icons/pi";



const WhyChooseUs = ({ dir }) => {
    const isDesktop = useBreakpointValue({
      base: false,
      lg: true,
    });
  
    return (
      <>
        <Container
          maxW={'7xl'}
          mx="auto"
          py={12}
          spacing={10}
        >
          <SimpleGrid columns={{ base: 1, md: 2 }} spacing={8}>
  
            {/* Left Side */}
            <Link _hover={{ textDecor: 'none' }} role="group" order={{ base: 2, md: 1 }}>
            <Stack bgColor="#fff" p={8} mx={4}>
                <Stack justify="center">
                <Box overflow="hidden">
                    <Image
                    alt="Our Service image"
                    src={img}
                    objectFit="cover"
                    transition="all 0.2s"
                    _groupHover={{
                        transform: 'scale(1.05)',
                    }}
                    />
                </Box>

                <HStack bgColor="" position="relative">
                    <Box position="relative">
                    <VStack align="left" spacing={4} py={6}>
                        <Heading lineHeight={1.1} fontWeight={400} fontSize={{ base: 'md', md: 'xl' }}>
                        <Text className="cairoB" color="#0b2a49">
                            {dir === 'ltr' ? 'Quality Craftsmanship' : 'جودة الحرفية'}
                        </Text>
                        </Heading>
                        <Text className="cairoR" fontSize={{ md: 'md', base: 'sm' }}>
                        {dir === 'ltr' ? (
                            <>
                            We take pride in delivering high-quality craftsmanship 
                            {isDesktop && <br />}that stands the test of time
                            </>
                        ) : (
                            <>
                            نحن نفخر بتقديم حرفية عالية الجودة
                             تثبت صلابتها{isDesktop && <br />}  عبر الزمن
                            </>
                        )}
                        </Text>

                        <Box mt={4} borderRadius="md" padding="1.5" boxShadow="md">
                        <SocialButtonGroup />
                        </Box>
                    </VStack>
                    </Box>

                    {/* Image for Desktop */}
                    {isDesktop && (
                    <Image
                        boxSize="210px"
                        alt="Our Service image"
                        src={img2}
                        objectFit="cover"
                        position="absolute"
                        zIndex={10}
                        right={dir === 'ltr' ? '-78' : '377px;'}
                        top="6"
                    />
                    )}
                </HStack>

                <HStack alignContent="center">
                    <Icon as={FcFactoryBreakdown} w={10} h={10} />
                    <Text className="cairoS" color="#0b2a49">
                    {dir === 'ltr' ? '10 construction work ongoing' : '10 أعمال بناء قائمة'}
                    </Text>
                </HStack>
                </Stack>
            </Stack>
            </Link>


            {/* Right Side */}
            <Stack order={{ base: 1, md: 2 }} gap={0} bgColor={""} pt={{ base: 2, md: 5 }} pr={dir === 'ltr' ? '4' : isDesktop && '150px'} pl={{ base: dir === 'ltr' ? '4' : '', md: dir === 'ltr' ? '150px' : '' }} justifyContent={"start"} alignItems={"left"}>
              <HStack>
                {/* Language-specific text */}
                {dir === 'ltr' &&
                  <Text className='Glook' fontSize={{ md: 'md', base: 'sm', }} color={"#ad831d"}>
                    The reason
                  </Text>
                }
                {dir === 'rtl' &&
                  <Text className="cairoR" fontSize={{ md: 'md', base: 'sm', }} color={"#ad831d"}>
                    السبب
                  </Text>
                }
  
                <Box position='relative' w='230px'>
                  <Divider border={"20px 20px"} borderColor={"#ad831d"} />
                </Box>
              </HStack>
  
              {/* English */}
              {dir === 'ltr' &&
                <Text mt={5} className='Glook' color={"#374151"} fontWeight={600} fontSize={{ base: '2xl', sm: 'xl', lg: '4xl' }}  >
                  WHY YOU CHOOSE US
                </Text>
              }
              {dir === 'rtl' &&
                <Text mt={5} className="cairoB" color={"#374151"} fontWeight={600} fontSize={{ base: '2xl', sm: 'xl', lg: '4xl' }}  >
                  لماذا عليك إختيارنا
                </Text>
              }
  
              <Stack>
                <Stack spacing={6} mt={10}>
  
                  {/* Language-specific text */}
                  {dir === 'ltr' &&
                    <HStack spacing={4} alignItems={"start"}>
                      <Box bgColor={"#0b2a49"} p={4}>
                        <Icon as={MdEngineering} color={"#ad831d"} boxSize={8} />
                      </Box>
  
                      <VStack alignItems={"left"} spacing={0}>
                        <Text className='PopM' fontSize={{ md: 'md', base: 'sm', }}>
                          Expertise
                        </Text>
                        <Text className='Pop' fontSize={{ md: 'sm', base: 'sm', }} color={"gray.700"} textAlign={"justify"}>
                          Our team of skilled professionals brings years of experience and expertise to every project.
                        </Text>
                      </VStack>
                    </HStack>
                  }
                  {dir === 'rtl' &&
                    <HStack spacing={4} alignItems={"start"}>
                      <Box bgColor={"#0b2a49"} p={4}>
                        <Icon as={MdEngineering} color={"#ad831d"} boxSize={8} />
                      </Box>
  
                      <VStack alignItems={"right"} spacing={0}>
                        <Text className="cairoS" fontSize={{ md: 'md', base: 'sm', }}>
                          الخبرة
                        </Text>
                        <Text className="cairoM" fontSize={{ md: 'sm', base: 'sm', }} color={"gray.700"} textAlign={"justify"}>
                          يجلب فريقنا من المحترفين المهرة سنوات من الخبرة والتخصص إلى كل مشروع.
                        </Text>
                      </VStack>
                    </HStack>
                  }
  
                  {/* Language-specific text */}
                  {dir === 'ltr' &&
                    <HStack spacing={4} alignItems={"start"}>
                      <Box bgColor={"#0b2a49"} p={4}>
                        <Icon as={PiHandshake} color={"#ad831d"} boxSize={8} />
                      </Box>
  
                      <VStack alignItems={"left"} spacing={0}>
                        <Text className='PopM' fontSize={{ md: 'md', base: 'sm', }}>
                          Client-Centric Approach
                        </Text>
                        <Text className='Pop' fontSize={{ md: 'sm', base: 'sm', }} color={"gray.700"} textAlign={"justify"}>
                          Your satisfaction is our priority. We collaborate closely with you to understand your vision and exceed your expectations.
                        </Text>
                      </VStack>
                    </HStack>
                  }
                  {dir === 'rtl' &&
                    <HStack spacing={4} alignItems={"start"}>
                      <Box bgColor={"#0b2a49"} p={4}>
                        <Icon as={PiHandshake} color={"#ad831d"} boxSize={8} />
                      </Box>
  
                      <VStack alignItems={"right"} spacing={0}>
                        <Text className='cairoS' fontSize={{ md: 'md', base: 'sm', }}>
                          نهج موجه نحو العميل
                        </Text>
                        <Text className='cairoM' fontSize={{ md: 'sm', base: 'sm', }} color={"gray.700"} textAlign={"justify"}>
                          رضاك هو أولويتنا. نتعاون بشكل وثيق معك لفهم رؤيتك وتجاوز توقعاتك.
                        </Text>
                      </VStack>
                    </HStack>
                  }
  
                  {/* Language-specific text */}
                  {dir === 'ltr' &&
                    <HStack spacing={4} alignItems={"start"} bgColor={""}>
                      <Box bgColor={"#0b2a49"} p={4}>
                        <Icon as={MdMoreTime} color={"#ad831d"} boxSize={8} />
                      </Box>
  
                      <VStack alignItems={"left"} spacing={0}>
                        <Text className='PopM' fontSize={{ md: 'md', base: 'sm', }}>
                          Time Delivery
                        </Text>
                        <Text className='Pop' fontSize={{ md: 'sm', base: 'sm', }} color={"gray.700"} textAlign={"justify"}>
                          We value your time. Our efficient project management ensures timely completion without compromising quality.
                        </Text>
                      </VStack>
                    </HStack>
                  }
                  {dir === 'rtl' &&
                    <HStack spacing={4} alignItems={"start"} bgColor={""}>
                      <Box bgColor={"#0b2a49"} p={4}>
                        <Icon as={MdMoreTime} color={"#ad831d"} boxSize={8} />
                      </Box>
  
                      <VStack alignItems={"right"} spacing={0}>
                        <Text className='cairoS' fontSize={{ md: 'md', base: 'sm', }}>
                          تسليم في الوقت المحدد
                        </Text>
                        <Text className='cairoM' fontSize={{ md: 'sm', base: 'sm', }} color={"gray.700"} textAlign={"justify"}>
                          نقدر وقتك. إدارة مشروعنا الفعالة تضمن الانتهاء في الوقت المناسب دون المساس بالجودة.
                        </Text>
                      </VStack>
                    </HStack>
                  }
  
                </Stack>
              </Stack>
            </Stack>
  
          </SimpleGrid>
        </Container>
      </>
    )
  }
  
  export default WhyChooseUs;






// import React from 'react'
// import {
//     Container,
//     SimpleGrid,
//     Image,
//     Link,
//     Heading,
//     Text,
//     Stack,
//     StackDivider,
//     Icon,
//     useBreakpointValue,
//     HStack,
//     VStack,
//     Box,
//     Divider,
//   } from '@chakra-ui/react';
//   import img from '../../../assets/img/ai/chooseUs.jpg'
//   import img2 from '../../../assets/img/ai/img2.jpg'
// import SocialButtonGroup from './SocialButtonGroup';
// import { FcFactoryBreakdown } from "react-icons/fc";
// import { MdMoreTime } from "react-icons/md";
// import { MdEngineering } from "react-icons/md";
// import { PiHandshake } from "react-icons/pi";




// const WhyChooseUs = ({dir}) => {

//     const isDesktop = useBreakpointValue({
//         base: false,
//         lg: true,
//       });
      


//   return (
//     <>
//     <Container
//       maxW={'7xl'}
//       mx="auto"
//     //   px={{
//     //     base: '4',
//     //     md: '8',
//     //     lg: '12',
//     //   }}
//       py={12}
//       spacing={10}
//     //   height={"80vh"}
//     >

//       <SimpleGrid columns={{ base: 1, md: 2 }} spacing={8} >

//         <Link _hover={{ textDecor: 'none', }} role="group" order={{ base: 2, md: 1 }}>
//             <Stack bgColor={"#fff"} p={8}  mx={4}>
//             <Stack justify={'center'} >
//                 <Box overflow="hidden">
//                     <Image
                    
//                         alt={'Our Service image'}
//                         src={img}
//                         objectFit={'cover'}
//                         transition="all 0.2s"
//                         _groupHover={{
//                         transform: 'scale(1.05)',
//                         }}
//                     />
//                 </Box>

//                 <HStack bgColor={''}  position='relative'>
//                     <Box position='relative' >
//                         <VStack align={'left'} spacing={4} py={6}>
//                         <Heading lineHeight={1.1} fontWeight={400} fontSize={{ base: 'md', md: 'xl' }}>
//                             <Text className='Pop' color={'#0b2a49'}>
//                             Quality Craftsmanship
//                             </Text>
//                         </Heading>
//                         <Text className='Pop' fontSize={{ md: 'md', base: 'sm' }}>
//                                We take pride in delivering high-quality
//                                {isDesktop && <br />} craftsmanship that stands the test of time                        
//                         </Text>
//                         <Box
//                             mt={4}
//                             // bg={useColorModeValue('white', 'gray.800')}
//                             borderRadius="md"
//                             padding="1.5"
//                             boxShadow={'md'}
//                             >
//                             <SocialButtonGroup />
//                         </Box>
//                         </VStack>
//                     </Box>
                    

//                     {/* Anglais */}
//                     {isDesktop && dir === 'ltr' &&
//                         <Image
//                             boxSize='210px'
//                             alt={'Our Service image'}
//                             src={img2}
//                             objectFit={'cover'}
//                             position='absolute'
//                             zIndex={10}
//                             right='-78'
//                             top='6'
//                         />
//                     }

                    
//                     {/* Arabic */}
//                     {isDesktop && dir === 'rtl' &&
//                         <Image
//                             boxSize='210px'
//                             alt={'Our Service image'}
//                             src={img2}
//                             objectFit={'cover'}
//                             position='absolute'
//                             zIndex={10}
//                             right='377px;'
//                             top='6'
//                         />
//                     }
//                 </HStack>


//                 <HStack alignContent={'center'}>
//                     <Icon as={FcFactoryBreakdown} w={10} h={10}  />
//                     <Text className='Pop' color={'#0b2a49'}>10 construction work on going</Text>
//                 </HStack>


//             </Stack>
//             </Stack>
//         </Link>

//         <Stack order={{ base: 1, md: 2 }} gap={0} bgColor={""} pt={{ base: 2, md: 5}} pr={dir === 'ltr' ? '4' : '150px'} pl={{ base: dir === 'ltr' ? '4' : '', md: dir === 'ltr' ? '150px' : ''}}  justifyContent={"start"} alignItems={"left"}>
//             <HStack >
//                 {/* Anglais */}
//                 {dir === 'ltr' && 
//                 <Text className='Glook' fontSize={{md: 'md',base: 'sm',}} color={"#ad831d"}>
//                 The reason
//                 </Text>
//                 }

//                 {/* Arabic */}
//                 {dir === 'rtl' && 
//                 <Text className='Glook' fontSize={{md: 'md',base: 'sm',}} color={"#ad831d"}>
//                     السبب   
//                 </Text>
//                 }

//                 <Box position='relative' w='230px'>
//                     <Divider border={"20px 20px"} borderColor={"#ad831d"}/>
//                 </Box>
//             </HStack>

               
//           {/* Anglais */}
//           {dir === 'ltr' &&
//           <Text p={0} mt={5} className='Glook' color={"#374151"} fontWeight={600} fontSize={{ base: '2xl', sm: 'xl', lg: '4xl' }}  >
//               WHY YOU CHOOSE US
//           </Text>
//           }

//           {/* Arabic */}
//           {dir === 'rtl' &&
//           <Text p={0} mt={5} className='Glook' color={"#374151"} fontWeight={600} fontSize={{ base: '2xl', sm: 'xl', lg: '4xl' }}  >
//               لماذا عليك إختيارنا
//           </Text>
//           }
//           <Stack>


//         <Stack spacing={6} mt={10}>

//             <HStack spacing={4} alignItems={"start"}>
//                 <Box bgColor={"#0b2a49"} p={4}>
//                     <Icon as={MdEngineering} color={"#ad831d"} boxSize={8}/>
//                 </Box>

//                 <VStack alignItems={"left"}  spacing={0}>
//                     <Text className='PopM' m={0} fontSize={{md: 'md',base: 'sm',}}>
//                         Expertise
//                     </Text>
//                     <Text className='Pop' fontSize={{md: 'sm',base: 'sm',}} color={"gray.700"}  textAlign={"justify"}>
//                         Our team of skilled professionals brings years of experience and expertise to every project.
//                     </Text>
//                 </VStack>
               
//             </HStack>

//             <HStack spacing={4} alignItems={"start"}>
//                 <Box bgColor={"#0b2a49"} p={4}>
//                     <Icon as={PiHandshake} color={"#ad831d"} boxSize={8}/>
//                 </Box>

//                 <VStack alignItems={"left"}  spacing={0}>
//                     <Text className='PopM' fontSize={{md: 'md',base: 'sm',}}>
//                          Client-Centric Approach
//                     </Text>
//                     <Text className='Pop' fontSize={{md: 'sm',base: 'sm',}} color={"gray.700"} textAlign={"justify"}>
//                         Your satisfaction is our priority. We collaborate closely with you to understand your vision and exceed your expectations.
//                     </Text>
//                 </VStack>
               
//             </HStack>

//             <HStack spacing={4} alignItems={"start"} bgColor={""}>
//                 <Box bgColor={"#0b2a49"} p={4}>
//                     <Icon as={MdMoreTime} color={"#ad831d"} boxSize={8}/>
//                 </Box>

//                 <VStack alignItems={"left"} spacing={0}>
//                     <Text className='PopM' fontSize={{md: 'md',base: 'sm',}}>
//                         Time Delivery
//                     </Text>
//                     <Text className='Pop' fontSize={{md: 'sm',base: 'sm',}} color={"gray.700"}  textAlign={"justify"}>
//                         We value your time. Our effecient project management ensures timely completion without compromising quality.
//                     </Text>
//                 </VStack>
               
//             </HStack>

//         </Stack> 
           
//         </Stack>
//         </Stack>
       
//       </SimpleGrid>
//     </Container>
//     </>
//   )
// }

// export default WhyChooseUs

