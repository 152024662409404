import res from '../../../assets/img/ai/residential.jpg'
import com from '../../../assets/img/ai/commercial.jpg'

export const posts = [
  {
    id: '1',
    title: 'Residential Construction',
    excerpt:
      'Crafting dream homes with precision and care, our Residential Construction services prioritize quality craftsmanship and tailored solutions, ensuring a seamless journey from concept to completion.',
    image: res,
  },
  {
    id: '2',
    title: 'Commercial Construction',
    excerpt:
      'In the realm of Commercial Construction, we specialize in innovative solutions, managing large-scale projects with efficiency to create functional and aesthetically pleasing spaces that elevate your business environment.',
    image: com,
  },
  {
    id: '3',
    title: 'Commercial Construction',
    excerpt:
      'In the realm of Commercial Construction, we specialize in innovative solutions, managing large-scale projects with efficiency to create functional and aesthetically pleasing spaces that elevate your business environment.',
    image: com,
    
  }
]
