import React from 'react';
import {
  Container,
  Stack,
  Heading,
  Text,
  IconButton,
  SimpleGrid,
  useBreakpointValue,
} from '@chakra-ui/react';
import { BsBullseye } from 'react-icons/bs';
import illustration from './amiriyaHero.svg';
import illustrationAr from './amiriyaHeroAr.svg';
import { FaRegStar, FaRegLightbulb } from 'react-icons/fa';
import { FaHelmetSafety } from "react-icons/fa6";
import { MdOutlineEco } from 'react-icons/md';

export default function HowItWorks({ dir }) {
  const isDesktop = useBreakpointValue({
    base: false,
    lg: true,
  });

  return (
    <Container
      maxW={'7xl'}
      my={'10'}
      rounded={'2xl'}
      bgImage={dir === 'ltr' ? illustration : isDesktop && illustrationAr}
      backgroundSize={'cover'}
    >
      <Stack
        spacing={{ base: 8, md: 10 }}
        py={{ base: 10, md: 14 }}
        direction={{ base: 'column', md: 'row' }}
      >
        <Stack flex={3} spacing={{ base: 5, md: 10 }}>
          {dir === 'ltr' && (
            <Heading lineHeight={1.1} fontWeight={600} fontSize={{ base: '4xl', sm: '4xl', lg: '6xl' }}>
              <Text className="Glook" as={'span'} position={'relative'} color={'#0b2a49'} _after={{ content: "''", width: 'full', height: '30%', position: 'absolute', bottom: 1, left: 0, bg: '#ad831d', zIndex: -1 }}>
                Discover,
              </Text>
              <Text className="Glook" as={'span'} color={'#ad831d'}>
                {} Our Values!
              </Text>
            </Heading>
          )}

          {dir === 'rtl' && (
            <Heading lineHeight={1.1} fontWeight={600} fontSize={{ base: '4xl', sm: '4xl', lg: '6xl' }}>
              <Text className="cairoB" as={'span'} position={'relative'} color={'#0b2a49'} _after={{ content: "''", width: 'full', height: '30%', position: 'absolute', bottom: 1, left: 0, bg: '#ad831d', zIndex: -1 }}>
                إكتشف،
              </Text>
              <Text className="cairoB" as={'span'} color={'#ad831d'}>
                {} قيمنا!
              </Text>
            </Heading>
          )}

          <Example dir={dir} />
        </Stack>
      </Stack>
    </Container>
  );
}

const Example = ({ dir }) => {
  return (
    <SimpleGrid columns={{ base: '1', md: '2' }} spacing={5} maxW={'5xl'}>
      <ValueItem dir={dir} icon={<BsBullseye />} text="Client-Centric Approach" />
      <ValueItem dir={dir} icon={<FaRegStar />} text="Excellence" />
      <ValueItem dir={dir} icon={<FaRegLightbulb />} text="Innovative Solutions" />
      <ValueItem dir={dir} icon={<MdOutlineEco />} text="Sustainability" /> 
      <ValueItem dir={dir}  icon={<FaHelmetSafety />} text="Safety and Professionalism Commitment" />
    </SimpleGrid>
  );
};

const ValueItem = ({ icon, text, dir }) => {
  const translatedText = getTranslatedText(text, dir);

  return (
    <Stack direction="row" alignItems="center">
      <IconButton isRound={true} variant="solid" bgColor="#0b2a49" color="#fff" size="md" marginRight="10px" icon={icon} />
      <Text className="cairoR" fontSize={{ base: '15px', md: '18px' }} style={{ textAlign: 'justify' }}>
        {translatedText}
      </Text>
    </Stack>
  );
};

const getTranslatedText = (text, dir) => {
  if (dir === 'rtl') {
    switch (text) {
      case 'Client-Centric Approach':
        return 'توجه العميل المركزي';
      case 'Excellence':
        return 'التميز';
      case 'Innovative Solutions':
        return 'حلول مبتكرة';
      case 'Sustainability':
        return 'الاستدامة';
      case 'Safety and Professionalism Commitment':
        return 'التفاني في السلامة والاحترافية';
      default:
        return text;
    }
  }

  return text;
};
