import { Box, Button, Flex, Heading, HStack, Img, Stack, Text, createIcon, useColorMode, Badge, useBreakpointValue } from '@chakra-ui/react'
import * as React from 'react'
import { Link } from 'react-router-dom'
import { useState } from 'react'
import Video from './Video'
// import img from '../../../assets/img/ai/bgImg.jpg'
import img from '../../../assets/img/ai/amiriyaHerotest.jpg'
import imgMobile from '../../../assets/img/ai/bgImgMobile.jpg'
import { ChevronRightIcon } from '@chakra-ui/icons'

const PlayIcon = createIcon({
  displayName: 'PlayIcon',
  viewBox: '0 0 58 58',
  d: 'M28.9999 0.562988C13.3196 0.562988 0.562378 13.3202 0.562378 29.0005C0.562378 44.6808 13.3196 57.438 28.9999 57.438C44.6801 57.438 57.4374 44.6808 57.4374 29.0005C57.4374 13.3202 44.6801 0.562988 28.9999 0.562988ZM39.2223 30.272L23.5749 39.7247C23.3506 39.8591 23.0946 39.9314 22.8332 39.9342C22.5717 39.9369 22.3142 39.8701 22.0871 39.7406C21.86 39.611 21.6715 39.4234 21.5408 39.1969C21.4102 38.9705 21.3421 38.7133 21.3436 38.4519V19.5491C21.3421 19.2877 21.4102 19.0305 21.5408 18.8041C21.6715 18.5776 21.86 18.3899 22.0871 18.2604C22.3142 18.1308 22.5717 18.064 22.8332 18.0668C23.0946 18.0696 23.3506 18.1419 23.5749 18.2763L39.2223 27.729C39.4404 27.8619 39.6207 28.0486 39.7458 28.2713C39.8709 28.494 39.9366 28.7451 39.9366 29.0005C39.9366 29.2559 39.8709 29.507 39.7458 29.7297C39.6207 29.9523 39.4404 30.1391 39.2223 30.272Z',
})



const Hero = ({dir}) => {

  const { colorMode } = useColorMode();

  const isDesktop = useBreakpointValue({
    base: false,
    lg: true,
  });


  const [showVideo, setShowVideo] = useState(false);

  const handlePlayVideo = () => {
    setShowVideo(true);
  };

  const handleCloseVideo = () => {
    setShowVideo(false);
  };

console.log('dir',dir);

  return (
    <Box as="section" minH="140px" position="relative">
      <Box  py="40" position="relative" zIndex={1}>
        <Box
          maxW={{
            base: 'xl',
            md: '7xl',
          }}
          mx="auto"
          my="auto"
          px={{
            base: '6',
            md: '8',
          }}
          color="white"
          // bgColor={'red'}
          
        >
          <Box maxW="2xl">
          <Badge
                // bgColor="#0b2a49"
                colorScheme="blue"
                className='cairoR'
                // color="#fff"
                alignSelf="start"
                size={useBreakpointValue({
                  base: 'md',
                  md: 'lg',
                })}
                px={4}
                py={2}
                borderRadius={'full'}
              >
                {dir === 'ltr' ? 'Welcome to Alamiriya' : 'مرحبا بك في العامرية'}
              </Badge>

            {dir === 'ltr' && 
            <Text
              className='Glook' 
                color={"#000"}
                fontSize={{base:"md", md: '3xl',}}
                fontWeight={'bold'}
                size={{base: "xl", md: '3xl',}}
                 >
                  Premier Construction Engineering Solutions: Shaping the Future with Expertise and Excellence
            </Text>
            }

            {dir === 'rtl' && 
            <Text
                className='cairoB'
                color={"#000"}
                fontSize={{base:"md", md: '4xl',}}
                fontWeight={'bold'}
                size={{base: "xl", md: '3xl',}}
                 >
                  حلول هندسة البناء الرائدة: تشكيل المستقبل بالخبرة والتميز
            </Text>
            }

            {/* Anglais */}
            {dir === 'ltr' && 
            <Text fontSize={{ md: 'md', base: 'sm', }} mt="4" maxW="lg" color={"#000"}
            >
              Pioneering innovation, advancing projects with excellence, and shaping sustainable environments. Our commitment to precision and passion establishes us as the foremost authority in construction methodologies, engineering design, and sustainable development.            </Text>
            }

            {/* Arabic */}
            {dir === 'rtl' && 
            <Text className='cairoM' fontSize={{ md: 'sm', base: 'sm', }} mt="4" maxW="lg" color={"#000"}
            >
                الريادة في الابتكار ، تطوير المشاريع بامتياز وتشكيل بيئات مستدامة. إن التزامنا بالدقة والشغف يؤسسنا السلطة الأولى في منهجيات البناء والتصميم الهندسي والتنمية المستدامة. نحن نسعى جاهدين لتحقيق رؤيتنا من خلال التفوق في الابتكار وتحقيق أعلى معايير الجودة والاستدامة.            </Text>
            }

            <Stack
              direction={{
                base: 'column',
                md: 'row',
              }}
              mt="10"
              spacing="4"
            >
            <Link to={`/mot-du-directeur`}>
               
              <Button
                as="a"
                w={"full"}
                // colorScheme='orange' 
                color={"#fff"}
                className='cairoR'
                bgColor={"#0b2a49"}
                _hover={{
                  bg: '#051d30', }} 
                px="8"
                rounded="full"
                size="lg"
                fontSize="md"
                fontWeight="bold"
                righIcon={<ChevronRightIcon h={6} w={6} color={'gray.300'} />}
                

              >
                {dir === 'ltr' ? 'Word from the Executive Director' : 'كلمة المدير العام' }
              </Button>
            </Link>
            {/* <Button
              onClick={handlePlayVideo}
              rounded={'full'}
              bgColor={"#0b2a49"}
                _hover={{
                  bg: '#051d30', }} 
              size={'lg'}
              fontWeight={'normal'}
              color="white"
              px={6}
              leftIcon={<PlayIcon h={6} w={6} color={'gray.300'} />}>
              À propos de nous
            </Button> */}
   
              
            </Stack>
          </Box>
        </Box>
      </Box>
      <Flex id="image-wrapper" position="absolute" insetX="19%" insetY="50" w="80%" h="80%" overflow="hidden" align="center"
      >
        <Box position="relative" w="full" h="full">
          <Img
            src={isDesktop ? img : imgMobile}
            alt="Main Image"
            w="full"
            h="full"
            objectFit="cover"
            // objectPosition="top bottom"
            position="absolute"
            style={{transform: dir === 'rtl' ? 'rotateY(180deg)' : ''}}
          />
          <Box position="absolute" w="full" h="full" />
        </Box>
      </Flex>

      {/* {showVideo && <Video onClose={handleCloseVideo} />} */}

    </Box>
    
  )
}

export default Hero


