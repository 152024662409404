import React from 'react';
import { ButtonGroup, Button, Box, useColorModeValue, useColorMode, Text } from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import { MdCall, MdLockReset, MdTrackChanges } from 'react-icons/md';
import { VscFeedback } from 'react-icons/vsc';
import { BsBookmarkStar } from 'react-icons/bs';
import { FaHome } from 'react-icons/fa';
import { MdLanguage } from "react-icons/md";

const MobileMenu = ({ dir, OnchangeDir }) => {


  return (
    <Box
      bg={useColorModeValue('white', 'gray.800')}
      p={2}
      borderBottom="8px"
      borderColor="#ad831d"
    >

      <Box className="d-flex">
        <Link to={`/`}>
          <Button className='cairoR' leftIcon={<FaHome />} variant="outline-primary">{dir === 'ltr' ? 'Acceuil' : 'الرئيسية'}</Button>
        </Link>
      </Box>

      <Box className="d-flex">
        <Link to={`/contact`}>
          <Button className='cairoR' leftIcon={<MdCall />} variant="outline-primary">{dir === 'ltr' ? 'Contact Us' : 'راسلنا'}</Button>
        </Link>
      </Box>

      <Box className="d-flex">

      <Button  variant="outline-primary" leftIcon={<MdLanguage/>} bgColor={'white'} onClick={() => OnchangeDir(dir === 'ltr' ? 'rtl' : 'ltr')}>
              {dir === 'ltr' ? (
                <Text className='cairoR'>Arabic</Text>

              ) : (
                <Text className='cairoR'>الإنجليزية</Text>
              )}
            </Button>
      </Box>

    </Box>
  );
};

export default MobileMenu;

