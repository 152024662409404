import { ButtonGroup, Icon, IconButton, useColorModeValue } from '@chakra-ui/react';
import * as React from 'react';
import { FaFacebookF } from 'react-icons/fa';
import { AiFillInstagram } from 'react-icons/ai';
import { IoLogoWhatsapp } from 'react-icons/io';

const options = [
  {
    icon: FaFacebookF,
    label: 'Facebook',
    link: 'https://web.facebook.com/alamiriya.eng', // Replace with your Facebook page URL
  },
  {
    icon: AiFillInstagram,
    label: 'Instagram',
    link: 'https://www.instagram.com/alamiriya.eng', // Replace with your Instagram page URL
  },
  {
    icon: IoLogoWhatsapp,
    label: 'WhatsApp',
    link: 'https://wa.me/+212643521382', // Replace with your WhatsApp number
  },
];



const SocialButtonGroup = () => {
  const iconColor = useColorModeValue('gray.600', 'gray.400');

  return (
    <ButtonGroup variant="ghost" width="full" size="sm" spacing="1">
      {options.map((option) => (
        <IconButton
          key={option.label}
          rounded="sm"
          sx={{
            ':not(:hover)': {
              color: iconColor,
            },
          }}
          _focus={{
            boxShadow: 'none',
          }}
          _focusVisible={{
            boxShadow: 'outline',
          }}
          width="full"
          as="a" // Use anchor tag for redirection
          href={option.link}
          target="_blank" // Open link in a new tab
          rel="noopener noreferrer"
          aria-label={option.label}
          icon={<Icon as={option.icon} boxSize="5" />}
        />
      ))}
    </ButtonGroup>
  );
};

export default SocialButtonGroup;
